import { useEffect, useState } from 'react';
import {
  components,
  MultiValueGenericProps,
  SingleValueProps,
} from 'react-select';
import Select from 'react-select';
import {
  Box,
  Flex,
  Heading,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import JobsAddressModal from '../Jobs/Modal/JobAddress';
import {
  listClients,
  useDeleteClientLocationMutation,
} from 'store/client.slice';
import { useDispatch, useSelector } from 'react-redux';
import ContactsModal from '../Clients/Contacts/Modal';
import ContactAddModal from './contactModal';
import CreatableSelect from 'react-select/creatable';
import { filter } from 'lodash';
import { getLead } from 'store/leads.slice';

const CustomOption = ({
  label,
  value,
  innerProps,
}: {
  label: string;
  value: number;
  innerProps: any;
}) => {
  return (
    <Flex
      sx={{ justifyContent: 'space-between', p: '16px', cursor: 'pointer' }}
      _active={{
        backgroundColor: '#4481F7',
        color: 'white',
      }}
      _hover={{
        backgroundColor: '#E0EBFD',
        color: '#1B202B',
      }}
    >
      <Box sx={{ fontWeight: label === 'Create New Contact' && 600 }}>
        <Text
          onClick={(e) => {
            innerProps.onClick(e);
          }}
        >
          {label}
        </Text>
      </Box>
    </Flex>
  );
};

export default function AtsSelectContact(FieldProps: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const [inputValue, setInputValue] = useState('');
  const [hasCreate, setHasCreate] = useState(true);
  const isSaveInput = FieldProps.isSaveInput || false;
  const isSaveName = FieldProps.isSaveName || false;
  const isMulti = FieldProps.isMulti || false;
  const options = FieldProps.options;

  const handleCreateContact = (inputValue: string) => {
    if (isSaveInput) {
      if (isSaveName) {
        setName(inputValue);
      } else {
        setEmail(inputValue);
      }
    }
    onOpen();
  };

  useEffect(() => {
    if (FieldProps.hasCreate !== undefined && FieldProps.hasCreate !== null) {
      setHasCreate(FieldProps.hasCreate);
    }
  }, [FieldProps.hasCreate]);

  let filteredOptions: any[] = options
    ?.filter((option: any) =>
      option?.label?.toLowerCase().includes(inputValue.toLowerCase())
    )
    ?.slice(0, 5);

  const handleInputChange = (e: any) => {
    setInputValue(e);
  };

  // Function to remove email part from label
  const removeEmailFromLabel = (label: string) =>
    label?.replace(/<[^>]*>/g, '').trim() || '';

  // Custom SingleValue component
  const SingleValue = (props: SingleValueProps<any>) => {
    return (
      <components.SingleValue {...props}>
        {removeEmailFromLabel(props.data.label)}
      </components.SingleValue>
    );
  };

  // Custom MultiValueLabel component
  const MultiValueLabel = (props: MultiValueGenericProps<any>) => {
    return (
      <components.MultiValueLabel {...props}>
        {removeEmailFromLabel(props.data.label)}
      </components.MultiValueLabel>
    );
  };

  return (
    <>
      <CreatableSelect
        {...FieldProps.field}
        onChange={(option: any) => {
          if (option) {
            if (isMulti) {
              if (!option?.[option?.length - 1]?.__isNew__) {
                FieldProps.form.setFieldValue(FieldProps.field.name, option);
              }
            } else if (option.label !== '' && !option?.__isNew__) {
              FieldProps.form.setFieldValue(FieldProps.field.name, option);
            }
          }
          if (FieldProps.onChange) {
            FieldProps.onChange(option);
          }
        }}
        options={filteredOptions}
        placeholder={FieldProps.placeholder}
        defaultValue={FieldProps.defaultValue}
        components={{
          SingleValue,
          MultiValueLabel,
          Option: ({ label, data, innerProps }) => {
            return (
              <div
                {...innerProps}
                onClick={(e) => {
                  // PURPOSE OF THIS ONCLICK:
                  // ONCLICK IN FORMATCREATELABEL DOES NOT FILL ENTIRE OPTION DIV
                  if (typeof label !== 'string' && hasCreate) {
                    handleCreateContact(inputValue);
                  } else {
                    innerProps.onClick(e);
                  }
                }}
              >
                <CustomOption
                  label={label}
                  value={(data as any)?.value}
                  innerProps={innerProps}
                />
              </div>
            );
          },
        }}
        value={FieldProps.value}
        isMulti={isMulti}
        isClearable
        isValidNewOption={() => hasCreate}
        formatCreateLabel={(inputValue) => {
          return (
            <>
              {hasCreate && (
                <Box
                  w="100%"
                  h="inherit"
                  fontWeight="bold"
                  onClick={() => {
                    handleCreateContact(inputValue);
                  }}
                >
                  {`Create New Contact`}
                </Box>
              )}
            </>
          );
        }}
        onKeyDown={(e) => {
          if (isSaveInput && hasCreate) {
            // User presses "Enter" and Input is a new value
            if (
              e.key === 'Enter' &&
              !(
                options.filter((option: any) =>
                  option?.label
                    ?.toLowerCase()
                    .includes(inputValue.toLowerCase())
                ).length > 0
              )
            ) {
              handleCreateContact(inputValue);
            }
          }
        }}
        onInputChange={(e: any) => {
          if (FieldProps.onInputChange) {
            FieldProps.onInputChange(e);
          }
          handleInputChange(e);
        }}
        isDisabled={FieldProps.isDisabled}
        styles={FieldProps.styles}
      />
      {isOpen && (
        <ContactAddModal
          isOpen={isOpen}
          onClose={onClose}
          client={FieldProps.client}
          email={email}
          name={name}
          props={FieldProps}
          isMulti={isMulti}
          onCallback={FieldProps?.onCallback}
          isSaveInput={isSaveInput}
          pairPH={FieldProps?.pairPH}
        />
      )}
    </>
  );
}
