import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import JobsAddressModal from '../Jobs/Modal/JobAddress';
import {
  listClients,
  useDeleteClientLocationMutation,
} from 'store/client.slice';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';

const CustomOption = ({
  label,
  value,
  onEdit,
  innerProps,
}: {
  label: string;
  value: number;
  onEdit: () => void;
  innerProps: any;
}) => {
  const dispatch = useDispatch();

  const [reqDeleteLocation] = useDeleteClientLocationMutation();

  const handleRemoveAddress = () => {
    reqDeleteLocation({ id: value });
    dispatch(listClients({ selected_client_location: [] }));
  };

  return (
    <Flex
      sx={{ justifyContent: 'space-between', p: '16px', cursor: 'pointer' }}
      _active={{
        backgroundColor: '#4481F7',
        color: 'white',
      }}
      _hover={{
        backgroundColor: '#E0EBFD',
        color: '#1B202B',
      }}
    >
      <Box sx={{ fontWeight: label === 'Create New Address' && 600 }}>
        <Text
          onClick={(e) => {
            innerProps.onClick(e);
          }}
        >
          {label}
        </Text>
      </Box>
      {typeof label === 'string' && (
        <Flex ml="20px" gap="15px" alignItems="center">
          <Box
            sx={{ cursor: 'pointer', color: 'primary.800' }}
            onClick={onEdit}
          >
            <FAIcon iconName="pencil" />
          </Box>
          <Box
            sx={{ cursor: 'pointer', color: 'caution.800' }}
            onClick={() => {
              handleRemoveAddress();
            }}
          >
            <FAIcon iconName="trash" />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default function AtsSelectAddress(FieldProps: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [inputValue, setInputValue] = useState('');
  const [hasCreate, setHasCreate] = useState(true);
  const options = FieldProps.options;

  const { selected_client_location } = useSelector(
    (state: any) => state.clients
  );

  const [addressData, setAddressData] = useState({});

  const handleEditOption = (data: any) => {
    setAddressData(data.data || {});
    onOpen();
  };

  useEffect(() => {
    if (FieldProps.hasCreate !== undefined && FieldProps.hasCreate !== null) {
      setHasCreate(FieldProps.hasCreate);
    }
  }, [FieldProps.hasCreate]);

  useEffect(() => {
    if (Object.keys(selected_client_location).length > 0) {
      FieldProps.form.setFieldValue(
        FieldProps.field.name,
        selected_client_location
      );
    }
  }, [selected_client_location?.value]);

  let filteredOptions: any[] = options
    ?.filter((option: any) =>
      option?.label?.toLowerCase().includes(inputValue.toLowerCase())
    )
    ?.slice(0, 5);

  const handleInputChange = (e: any) => {
    setInputValue(e);
  };

  return (
    <>
      <CreatableSelect
        {...FieldProps.field}
        onChange={(option: any) => {
          if (option?.label != 'Create New Address') {
            FieldProps.form.setFieldValue(FieldProps.field.name, option);
          }
          if (FieldProps.onChange) {
            FieldProps.onChange(option);
          }
        }}
        options={filteredOptions}
        placeholder={FieldProps.placeholder}
        defaultValue={FieldProps.defaultValue}
        components={{
          Option: ({ label, data, innerProps }) => {
            return (
              <div
                {...innerProps}
                onClick={(e) => {
                  // PURPOSE OF THIS ONCLICK:
                  // ONCLICK IN FORMATCREATELABEL DOES NOT FILL ENTIRE OPTION DIV
                  if (typeof label !== 'string' && hasCreate) {
                    handleEditOption(inputValue);
                  } else {
                    innerProps.onClick(e);
                  }
                }}
              >
                <CustomOption
                  label={label}
                  value={(data as any)?.value}
                  onEdit={() => handleEditOption(data)}
                  innerProps={innerProps}
                />
              </div>
            );
          },
        }}
        value={FieldProps.value}
        isClearable
        isValidNewOption={() => hasCreate}
        formatCreateLabel={(inputValue) => {
          return (
            <>
              {hasCreate && (
                <Box
                  w="100%"
                  h="inherit"
                  fontWeight="bold"
                  onClick={() => {
                    handleEditOption(inputValue);
                  }}
                >
                  {`Create New Address`}
                </Box>
              )}
            </>
          );
        }}
        onKeyDown={(e) => {
          if (hasCreate) {
            // User presses "Enter" and Input is a new value
            if (
              e.key === 'Enter' &&
              !(
                options.filter((option: any) =>
                  option?.label
                    ?.toLowerCase()
                    .includes(inputValue.toLowerCase())
                ).length > 0
              )
            ) {
              handleEditOption(inputValue);
            }
          }
        }}
        onInputChange={(e: any) => {
          if (FieldProps.onInputChange) {
            FieldProps.onInputChange(e);
          }
          handleInputChange(e);
        }}
        isDisabled={FieldProps.isDisabled}
        styles={FieldProps.styles}
      />
      {isOpen && (
        <JobsAddressModal
          isOpen={isOpen}
          onClose={onClose}
          addressData={addressData}
        />
      )}
    </>
  );
}
