import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import moment from 'moment';

import {
  Box,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  useDisclosure,
  Link,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import JobsTabs from './Layout/Tabs';

import {
  getJob,
  useGetJobsMutation,
  useListJobsMutation,
  useToggleStatusMutation,
} from 'store/jobs.slice';
import { JobsData, JobsTypes, swalContent } from 'types';
import FAIcon from 'components/lib/FAIcon';
import { AtsConfirm } from 'utils/swal';
import JobsModal from '../../../../../components/app/Jobs/Modal/Job';
import JobsAdsModal from './Layout/TabPanel/JobAds/Modal';
import JobsRecruitersModal from 'components/app/Jobs/Modal/Recruiters';
import { useUserListMutation } from 'store/user.slice';

import Fire from 'assets/images/Fire.png';
import Url from 'assets/images/Url.svg';

import AtsEmailBody from 'components/app/EmailBody';
import {
  setJobNotes,
  useCreateJobNoteMutation,
  useListJobNoteMutation,
  useUpdateJobNoteMutation,
} from 'store/jobnote.slice';
import { CAREER_PAGE_URL } from 'constants/values';
import LoadingPage from 'components/app/Loading';
import { PaidJobModal } from 'components/app/Jobs/Modal/PaidJob';

interface JobProps {
  isOpen: boolean;
  onClose: () => void;
  rowId: number;
  totalJobs: number;
  handlePrevData: (lead: JobsData) => void;
  handleNextData: (lead: JobsData) => void;
}

function JobsDrawer({
  isOpen,
  rowId,
  onClose,
  totalJobs,
  handlePrevData,
  handleNextData,
}: JobProps) {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const { jobs, jobData, jobPgBtn, hasPaidJob } = useSelector(
    (state: JobsTypes) => state.jobs
  );
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);
  const { jobNotePgBtn, jobNoteData } = useSelector(
    (state: any) => state.jobNotes
  );

  const [reqToggleStatus] = useToggleStatusMutation();
  const [reqJobs] = useListJobsMutation();
  const [reqJobData, resJobData] = useGetJobsMutation();
  const [reqUsers] = useUserListMutation();
  const [reqNotes, resNotes] = useCreateJobNoteMutation();
  const [reqList] = useListJobNoteMutation();
  const [reqUpdateNotes, resUpdateNotes] = useUpdateJobNoteMutation();

  const [modalTitle, setModalTitle] = useState('Edit Job');
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [quilValue, setquilValue] = useState('');
  const [hasEditNote, SetHasEditNote] = useState(false);
  const [noteId, setNoteId] = useState(null);
  const [emailBodyKey, setEmailBodyKey] = useState(0);

  const hasClientLink = jobData?.client_job_link;
  const [hasJobAds, setHasJobAds] = useState(false);

  useEffect(() => {
    setHasJobAds(false);
    if (jobData?.jobAds && jobData?.jobAds?.[0]?.career_page_publish) {
      if (jobData?.jobAds?.[0]?.job_status_id === 1) setHasJobAds(true);
    }
  }, [jobData]);

  const {
    isOpen: isEditJobOpen,
    onOpen: onEditJobOpen,
    onClose: onEditJobClose,
  } = useDisclosure();

  const {
    isOpen: isModalJobOpen,
    onOpen: onModalJobOpen,
    onClose: onModalJobClose,
  } = useDisclosure();

  const {
    isOpen: isOpenPaid,
    onOpen: onOpenPaid,
    onClose: onClosePaid,
  } = useDisclosure();

  const stateTab: any = params.jobsTab;
  const jobID = params.jobsId;

  useEffect(() => {
    if (isOpen) {
      const getData =
        jobs && jobs.filter((job: JobsTypes) => job.id === Number(jobID))[0];
      dispatch(getJob({ jobData: getData }));
      reqJobData({ id: jobID });
      reqUsers({});
    }
  }, [isOpen, jobID, jobs]);

  useEffect(() => {
    if (hasPaidJob) {
      onOpenPaid();
    }
  }, [hasPaidJob]);

  useEffect(() => {
    if (jobNoteData) {
      setquilValue(jobNoteData?.notes);
      SetHasEditNote(true);
      setNoteId(jobNoteData?.id);
    } else {
      SetHasEditNote(false);
      setquilValue('');
      setNoteId(null);
    }
  }, [jobNoteData]);

  const handleOpenDrawer = () => {
    if (jobID) {
      return true;
    }
    return isOpen;
  };

  const content: swalContent = {
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this job data.',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const handleToggleStatus = async (job: JobsTypes) => {
    const status = job.job_status_id === 1 ? 'closed' : 'open';
    content.text = `You are about to mark this job as ${status}, continue?`;
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqToggleStatus({ id: job.id });
      reqJobData({ id: jobID });
      reqJobs({ data: jobPgBtn });
    }
  };

  const ButtonType = (type: string) => {
    if (type === 'screening-questions') return 'Add Question';
    if (type === 'recruitment-team') return 'Add Recruiter';
    if (type === 'job-ads') return 'Add Job Ad';
    return type;
  };

  const jobAge = (date: any) => {
    try {
      const created = moment(date);
      const today = moment();
      return today.diff(created, 'days');
    } catch (e) {
      return 'N/A';
    }
  };

  const locationParse = (location: any) => {
    try {
      if (location) {
        const loc = [location?.city, location?.state].join(', ');
        if (loc !== ', ') {
          return loc;
        }
      }
      return '';
    } catch (e) {
      console.log(e);
      return '';
    }
  };

  const createNotes = async () => {
    let data = {
      notes: quilValue,
    };
    setquilValue('');
    setEmailBodyKey((prevKey) => prevKey + 1);
    await reqNotes({ data, id: jobID });
  };

  const UpdateNotes = async () => {
    let data = {
      notes: quilValue,
    };
    if (noteId) reqUpdateNotes({ id: noteId, data });
  };

  const cancelUpdate = async () => {
    dispatch(setJobNotes({ jobNoteData: null }));
  };

  useEffect(() => {
    if (resNotes.isSuccess || resUpdateNotes.isSuccess) {
      reqJobData({ id: jobID });
      reqList({ data: jobNotePgBtn, id: jobID });
      setquilValue('');
    }
  }, [resNotes.isSuccess, resUpdateNotes.isSuccess]);

  return (
    <>
      {jobData && (
        <Drawer
          onClose={() => {
            onClose();
            navigate('/jobs');
            dispatch(getJob({ jobData: {} }));
            dispatch(getJob({ hasPaidJob: false }));
          }}
          isOpen={handleOpenDrawer()}
          size="xl"
        >
          <DrawerOverlay top="60px" />
          <DrawerContent
            maxW="95vw"
            top="62px! important"
            fontFamily="NunitoSans Regular"
          >
            <DrawerCloseButton
              position="absolute"
              top="0px"
              left="-40px"
              borderRadius="unset"
              p="20px"
              bg="#E6E9EE"
              color="default.secondarytext"
              _hover={{
                backgroundColor: 'none',
              }}
            />
            <Flex
              justifyContent="flex-end"
              alignItems="center"
              gap="12px"
              pl={4}
              pr={0}
              borderBottom="1px solid #E6E9EE"
              fontSize="14px"
            >
              <Flex gap="8px">
                {rowId === 0 || totalJobs === 1 ? (
                  <>
                    Showing <b>1</b> of 1
                  </>
                ) : (
                  <>
                    Showing <b>{rowId}</b> of {totalJobs}
                  </>
                )}
              </Flex>
              <Flex>
                <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                  <Button
                    iconName="chevron-left"
                    variant="ghost"
                    onClick={() => rowId !== 1 && handlePrevData(jobData)}
                    disabled={rowId === 1}
                  />
                </Box>

                <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                  <Button
                    iconName="chevron-right"
                    variant="ghost"
                    onClick={() =>
                      rowId !== totalJobs && handleNextData(jobData)
                    }
                    disabled={rowId === totalJobs}
                    borderLeft="1px solid #6B6C7B"
                    borderRadius="unset"
                  />
                </Box>
              </Flex>
            </Flex>
            <DrawerBody p="0">
              {resJobData.isLoading ? (
                <LoadingPage />
              ) : (
                <>
                  <Box
                    bg="default.white.800"
                    borderBottom="1px solid"
                    borderColor="default.white.400"
                    pt="19px"
                    px="20px"
                    pb="60px"
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      mb="8px"
                    >
                      <Flex flexDir="column">
                        <Flex
                          fontSize="20px"
                          fontWeight="600"
                          color="default.primarytext"
                          flexDirection="row"
                          alignItems="center"
                          gap="8px"
                          sx={{
                            padding: 0,
                            fontSize: '22px',
                            fontWeight: '700',
                            maxWidth: '500px',
                            whiteSpace: 'no-wrap',
                            overflow: 'hidden',
                          }}
                        >
                          {jobData?.job_type_id === 2 && (
                            <Image
                              src={Fire}
                              alt="Fire"
                              height="22px"
                              mb="3px"
                            />
                          )}
                          {jobData.title}
                        </Flex>
                        <Flex gap={2} fontSize="14px" color="default.gray.800">
                          <Box>{jobData.serial_number}</Box>|
                          <Box>
                            {jobData?.location &&
                              locationParse(jobData.location)}
                          </Box>
                          |
                          <Flex alignItems="center" gap="5px">
                            <FAIcon iconName="clock" color="purple" />
                            {jobAge(jobData.created_at)} day(s)
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex alignItems="center" gap={3}>
                        <ButtonGroup>
                          <Menu closeOnSelect={false}>
                            <MenuButton>
                              <Box
                                sx={{
                                  button: {
                                    background: 'none',
                                    radius: '6px',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    lineHeight: '16px',
                                    color: 'primary.600',
                                  },
                                }}
                              >
                                <Button rightIcon="chevron-down">
                                  Actions
                                </Button>
                              </Box>
                            </MenuButton>
                            <MenuList
                              fontSize="12px"
                              lineHeight="16px"
                              fontWeight="600"
                            >
                              <MenuItem
                                icon={<FAIcon iconName="pencil" />}
                                onClick={() => {
                                  // dispatch(getJob({ jobData: jobData }));
                                  onEditJobOpen();
                                  setModalTitle('Edit Job');
                                  setIsDuplicate(false);
                                  reqJobData({ id: jobID });
                                }}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                icon={<FAIcon iconName="copy" />}
                                onClick={() => {
                                  // dispatch(getJob({ jobData: jobData }));
                                  onEditJobOpen();
                                  setModalTitle('Duplicate Job');
                                  setIsDuplicate(true);
                                  reqJobData({ id: jobID });
                                }}
                              >
                                Duplicate
                              </MenuItem>
                              {jobData.job_status_id === 1 ? (
                                <MenuItem
                                  icon={<FAIcon iconName="ban" />}
                                  color="caution.800"
                                  onClick={() => {
                                    handleToggleStatus(jobData);
                                  }}
                                >
                                  Mark as Closed
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  icon={<FAIcon iconName="check" />}
                                  color="success.800"
                                  onClick={() => {
                                    handleToggleStatus(jobData);
                                  }}
                                >
                                  Mark as Open
                                </MenuItem>
                              )}
                            </MenuList>
                          </Menu>
                          <Box
                            sx={{
                              button: {
                                radius: '6px',
                                fontSize: '12px',
                                fontWeight: 600,
                                lineHeight: '16px',
                              },
                            }}
                          >
                            <Button
                              variant="solid"
                              onClick={() => {
                                navigate(
                                  `/jobs/${jobData.id}/view-candidates/in-progress/screen`
                                );
                                let params = {
                                  ...candidatePgBtn,
                                  job_status: 1,
                                };
                                localStorage.setItem(
                                  'candidatesFilter',
                                  JSON.stringify(params)
                                );
                              }}
                            >
                              View Candidates
                            </Button>
                          </Box>
                        </ButtonGroup>
                      </Flex>
                    </Flex>
                    <Flex
                      gap="8px"
                      fontSize="14px"
                      fontWeight={600}
                      color="primary.500"
                    >
                      {hasClientLink ? (
                        <Link
                          href={hasClientLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Flex alignItems="center" gap="5px">
                            Client Job Link
                            <Image src={Url} alt="link" height="18px" />
                          </Flex>
                        </Link>
                      ) : (
                        <Flex
                          alignItems="center"
                          gap="5px"
                          color="default.gray.800"
                        >
                          Client Job Link
                        </Flex>
                      )}
                      |
                      {hasJobAds ? (
                        <Link
                          href={`${CAREER_PAGE_URL}/job-details/${
                            jobData && jobData?.jobAds && jobData?.jobAds[0].id
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Flex alignItems="center" gap="5px">
                            Talently Job Ad
                            <Image src={Url} alt="link" height="18px" />
                          </Flex>
                        </Link>
                      ) : (
                        <Flex
                          alignItems="center"
                          gap="5px"
                          color="default.gray.800"
                        >
                          Talently Job Ad
                        </Flex>
                      )}
                    </Flex>
                  </Box>
                  <Box mt="-39px">
                    <JobsTabs />
                  </Box>
                </>
              )}

              {isEditJobOpen && (
                <JobsModal
                  isOpen={isEditJobOpen}
                  onClose={onEditJobClose}
                  modalTitle={modalTitle}
                  duplicate={isDuplicate}
                  jobId={Number(params.jobsId)}
                />
              )}
            </DrawerBody>

            {stateTab === 'job-ads' &&
              jobData &&
              jobData?.jobAds?.length === 0 && (
                <Box
                  position="sticky"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  p="16px 32px"
                  onClick={() => {
                    onModalJobOpen();
                  }}
                >
                  <Button leftIcon="plus" variant="solid">
                    {ButtonType(stateTab)}
                  </Button>
                </Box>
              )}

            {stateTab === 'recruitment-team' && (
              <Box
                position="sticky"
                bottom="0"
                background="default.white.100"
                boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                p="16px 32px"
                onClick={() => {
                  onModalJobOpen();
                }}
              >
                <Button leftIcon="plus" variant="solid">
                  {ButtonType(stateTab)}
                </Button>
              </Box>
            )}
            {stateTab === 'recruitment-team' && isModalJobOpen && (
              <JobsRecruitersModal
                isOpen={isModalJobOpen}
                onClose={onModalJobClose}
              />
            )}
            {stateTab === 'job-ads' && isModalJobOpen && (
              <JobsAdsModal isOpen={isModalJobOpen} onClose={onModalJobClose} />
            )}
            {stateTab === 'notes' && (
              <>
                <Box
                  position="absolute"
                  width="100%"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  p="16px 32px"
                >
                  <AtsEmailBody
                    key={emailBodyKey}
                    value={quilValue}
                    menubar={false}
                    onChange={setquilValue}
                    height={150}
                  />
                  <Box mt="3" textAlign={'right'}>
                    {hasEditNote ? (
                      <Flex gap={2} justifyContent="flex-end">
                        <Button variant="ghost" onClick={() => cancelUpdate()}>
                          Cancel
                        </Button>
                        <Button
                          variant="solid"
                          onClick={() => UpdateNotes()}
                          loading={resUpdateNotes.isLoading}
                        >
                          Update Note
                        </Button>
                      </Flex>
                    ) : (
                      <Button variant="solid" onClick={() => createNotes()}>
                        Add Note
                      </Button>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </DrawerContent>
          {isOpenPaid && (
            <PaidJobModal isOpen={isOpenPaid} onClose={onClosePaid} />
          )}
        </Drawer>
      )}
    </>
  );
}

export default JobsDrawer;
