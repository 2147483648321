import {
  Box,
  Checkbox,
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import LeadStatus from 'components/app/LeadStatus';
import moment from 'moment';
import React, { ReactElement, useMemo, useState } from 'react';
import { NewProspectAPIResponseType } from '../../types';

import EmailModal from 'components/app/Global/Email/Lead';
import FAIcon from 'components/lib/FAIcon';
import Pagination from 'Library/Pagination';
import { Outlet, useNavigate } from 'react-router';
import toUrl from 'utils/toUrl';
import SkeletonLoader from './components/SkeletonLoader';

interface TableProps {
  rows: NewProspectAPIResponseType[];
  onDateSort: () => void;
  onSubmitEmail: () => void;
  isLoading?: boolean;
  pagination: {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    totalEntries: number;
    onEntryChange: (entries: number) => void;
    currentCount: number;
    targetCount: number;
  };
}
export default function NewPropectsTable({
  rows,
  onDateSort,
  onSubmitEmail,
  isLoading,
  pagination: {
    totalPages,
    currentPage,
    onPageChange,
    totalEntries,
    onEntryChange,
    currentCount,
    targetCount,
  },
}: TableProps) {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const prospectIdList = useMemo(
    () => rows.map((lead: any) => lead.id),
    [rows]
  );
  const headers: { label: ReactElement | string }[] = useMemo(() => {
    function handleSelectAllRows(e: React.ChangeEvent<HTMLInputElement>) {
      const { checked } = e.target;

      if (checked) {
        setSelectedRows(prospectIdList);
      } else {
        setSelectedRows([]);
      }
    }
    return [
      {
        label: (
          <Checkbox
            onChange={handleSelectAllRows}
            isIndeterminate={
              selectedRows.length && rows.length !== selectedRows.length
            }
            isChecked={
              selectedRows.length && rows.length === selectedRows.length
            }
            defaultChecked={false}
            colorScheme="purple"
          />
        ),
      },
      { label: 'Prospect Details' },
      { label: 'Client Name' },
      { label: 'Contact Information' },
      { label: 'Client Job Link' },
      { label: 'Status ' },
      {
        label: (
          <Flex gap="10px" justifyContent="start" alignItems="center">
            Created Date
            <Flex onClick={onDateSort} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        ),
      },
    ];
  }, [selectedRows, onDateSort, rows.length, prospectIdList]);

  const [emailModal, setEmailModal] = useState<{
    open: boolean;
    rowId: number | null;
  }>({ open: false, rowId: null });

  function handleRowSelection(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.target;
    const leadId = parseInt(name);
    setSelectedRows((state) => [...state, leadId]);
    if (!checked) {
      setSelectedRows((state) => state.filter((id) => id !== leadId));
    }
  }

  function handleClick(lead: NewProspectAPIResponseType) {
    navigate(`/leads/new-prospects/${lead.id}/details`);
  }
  if (isLoading) return <SkeletonLoader />;
  return (
    <>
      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="inherit"
        sx={{ overflowY: 'auto' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              {headers.map((title, key: number) => (
                <Th
                  key={`lead-th-${key}`}
                  sx={{ position: 'sticky', top: 0, zIndex: 10 }}
                  bg="default.white.600"
                >
                  <Box color="default.gray.600">{title.label}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {rows.map((lead: NewProspectAPIResponseType, index: number) => (
              <Tr
                key={`lead-tr-${lead.id}`}
                _hover={{
                  bg: '#f8f9fa',
                }}
              >
                <Td w={50} cursor="pointer">
                  <Checkbox
                    onChange={handleRowSelection}
                    isChecked={selectedRows.includes(lead.id)}
                    name={lead.id.toString()}
                    key={lead.id}
                    colorScheme="purple"
                  />
                </Td>
                <Td
                  width={250}
                  onClick={() => handleClick(lead)}
                  cursor="pointer"
                >
                  <Box whiteSpace="pre-line" maxWidth="250px">
                    <Box
                      fontWeight="bold"
                      textTransform="capitalize"
                      style={{ textWrap: 'nowrap' }}
                    >
                      {[lead.first_name, lead.last_name].join(' ')}
                    </Box>
                    <Box fontSize="sm"> {lead.title}</Box>
                  </Box>
                </Td>
                <Td width="50px" p="5px 24px" fontSize="14px" cursor="pointer">
                  <Link
                    href={`/clients/my-clients/${lead.client?.id}/overview`}
                    target="_blank"
                  >
                    {lead?.client?.name}
                  </Link>
                </Td>

                <Td width={250} cursor="pointer">
                  <Box>
                    <Link
                      fontWeight="bold"
                      fontSize="sm"
                      onClick={() =>
                        setEmailModal({ open: true, rowId: index })
                      }
                    >
                      {lead.primary_email}
                    </Link>
                    <Box fontSize="sm"> {lead.personal_phone}</Box>
                  </Box>
                </Td>
                <Td p="5px 24px" fontSize="14px" cursor="pointer" maxW={350}>
                  <Box w="100%" isTruncated>
                    <Link href={toUrl(lead.client_job_link)} target="_blank">
                      {lead?.client_job_title || lead.client_job_link}
                    </Link>
                  </Box>
                </Td>
                <Td onClick={() => handleClick(lead)} cursor="pointer">
                  <LeadStatus status={lead.leadStatus.lead_status} />
                </Td>
                <Td
                  width="50px"
                  p="5px 24px"
                  fontSize="14px"
                  onClick={() => handleClick(lead)}
                  cursor="pointer"
                >
                  {moment.utc(lead?.created_at).format('MM/DD/YYYY')}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {emailModal.open ? (
          <EmailModal
            isOpen={emailModal.open}
            onClose={() => setEmailModal({ open: false, rowId: null })}
            email={rows[emailModal.rowId]?.primary_email || ''}
            id={rows[emailModal.rowId].id}
            allowedCategory={['Client', 'Contact', 'Signatures']}
          />
        ) : null}
      </TableContainer>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalEntries={totalEntries}
        onEntryChange={onEntryChange}
        currentCount={currentCount}
        targetCount={targetCount}
      />

      <Outlet />
    </>
  );
}
