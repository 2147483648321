// Formik components for handling forms
import { Field, Formik } from 'formik';

// Chakra UI components for alerts and modals
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

// Application-specific components
import AtsSelect2 from 'components/app/AtsSelect2';
import ScrollToFieldError from 'components/app/ScrollError';

// Local components and hooks
import HiredModal from '../HiredModal';
import { useChangeStatusServices } from './useChangeStatusServices';

interface ChangeStatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  id?: number;
  main_status: number;
  sub_status: number;
  idList?: number[];
  isBulkChangeStatus?: boolean;
  candidate?: any;
  onSuccess: (e: any) => void;
  jobDetails?: any;
  groupItem?: any;
}

export default function ChangeStatusModal({
  isOpen,
  onClose,
  id,
  main_status,
  sub_status,
  idList,
  candidate,
  isBulkChangeStatus = false,
  onSuccess,
  jobDetails,
  groupItem,
}: ChangeStatusModalProps) {
  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    initialValues,
    handleSubmit,
    minHeight,
    statOption,
    onFocusSelect,
    onBlurSelect,
    isLoading,
    isOpenHired,
    onCloseHired,
    callBackAction,
  } = useChangeStatusServices({
    sub_status,
    main_status,
    isBulkChangeStatus,
    idList,
    candidate,
    id,
    onSuccess,
    onClose,
    onOpenAlert,
  });

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="lg"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Change Status
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        {isOpenAlert && (
          <Box>
            <Alert status="error" justifyContent="space-around">
              <AlertIcon />
              <Box>
                <AlertTitle>Oh snap!</AlertTitle>
                <AlertDescription>
                  There was an error processing your request. Change a few
                  things up and try again.
                </AlertDescription>
              </Box>
              <CloseButton
                position="relative"
                right={-1}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}
        <ModalBody
          borderRadius="0 0 4px 4px"
          p={0}
          style={{ overflow: 'hidden' }}
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ values, handleSubmit, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box p="32px" minHeight={minHeight}>
                  <Box mb="34px">
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Current Job Status
                      </FormLabel>
                      <Field
                        name="sub_id"
                        options={statOption}
                        component={AtsSelect2}
                        defaultValue={values.sub_id}
                        onMenuOpen={() => onFocusSelect()}
                        onMenuClose={() => onBlurSelect()}
                      />
                    </FormControl>
                  </Box>
                </Box>

                <ModalFooter
                  position="sticky"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                >
                  <Button mr={3} onClick={() => onClose()} disabled={isLoading}>
                    Cancel
                  </Button>
                  <Button
                    variant="solid"
                    type="submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                  >
                    Update
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>

          {isOpenHired && (
            <HiredModal
              isOpen={isOpenHired}
              onCloseStatus={onClose}
              onClose={onCloseHired}
              candidate={candidate}
              onCallback={callBackAction}
              jobId={jobDetails?.job_id}
              jobDetails={jobDetails}
              groupItem={groupItem}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
