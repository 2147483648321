import { useDisclosure } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { updateApp } from 'store/app.slice';
import {
  useApprovalStatusMutation,
  useListAppprovalsMutation,
} from 'store/approval.slice';
import { loginAuth } from 'store/auth.slice';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import { useViewInterviewBodyMutation } from 'store/candidateinterview.slice';
import {
  initialCandidatePgBtn,
  listCandidates,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import {
  initialClientPageBtn,
  listClients,
  useMyListClientsMutation,
} from 'store/client.slice';
import {
  useGetCandidateSourceMutation,
  useGetMeetingTypesMutation,
  useGetGeneralConstantMutation,
} from 'store/constant.slice';
import {
  useActivityListMutation,
  useGetAcquisitionSourceMutation,
  useGetDealsMutation,
  useGetRecruitmentPipelinesMutation,
  useGetTopRecruitersMutation,
} from 'store/dashboard.slice';
import {
  initialJobPgBtn,
  listJobs,
  useGetDefaultPitchMutation,
  useListJobsMutation,
} from 'store/jobs.slice';
import {
  initialLeadPgBtn,
  listLeads,
  useGetTypesMutation,
  useListLeadsMutation,
} from 'store/leads.slice';
import { useGetScheduleListMutation } from 'store/outreach.slice';
import {
  updateTeam,
  useListTeamsMutation,
  useSwitchAccountMutation,
} from 'store/teams.slice';
import { useDefaultTemplateListMutation } from 'store/template.slice';
import {
  useCheckCurrentUserMutation,
  useUserListMutation,
  useViewProfileMutation,
} from 'store/user.slice';
import delay from 'utils/delay';
import { authStorage } from 'utils/localStorage';

interface teamMember {
  first_name: string;
  last_name: string;
  user_id: number;
  signedUrl: string;
  user: any;
  thumbnailSignedUrl: string;
  role: string;
}

export function useMainPageServices() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const auth = authStorage();
  const { isCongrats, isTalieShow, hasSwitchedAccount, isBackDrop } =
    useSelector((state: any) => state.app);
  const { first_name } = useSelector((state: any) => state.auth);

  const [reqApprovals, resApprovals] = useListAppprovalsMutation();
  const [reqSwitch, resSwitch] = useSwitchAccountMutation();
  const [reqGetInterviewBody] = useViewInterviewBodyMutation();
  const [reqPitch, resPitch] = useGetDefaultPitchMutation();
  const [reqDefTempList, resDefTempList] = useDefaultTemplateListMutation();
  const [reqUsers, resUsers] = useUserListMutation();
  const [reqViewProfile, resViewProfile] = useViewProfileMutation();
  const [reqTeams, resTeams] = useListTeamsMutation();
  const [reqCheckUser, resCheckUser] = useCheckCurrentUserMutation();
  const [getMeetingType] = useGetMeetingTypesMutation();
  const [reqGenConstant, resGenConstant] = useGetGeneralConstantMutation();

  // const [teamMembers, setTeamMembers] = useState<teamMember[]>([])
  const [profile, setProfile] = useState<any>(null);
  const [isNavBar, setIsNavBar] = useState<boolean>(true);
  const {
    isOpen: isOpenParseQueue,
    onClose: onCloseParseQueue,
    onOpen: onOpenParseQueue,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenFooterNotif,
    onClose: onCloseFooterNotif,
    onOpen: onOpenFooterNotif,
  } = useDisclosure({ defaultIsOpen: false });

  const handleOpenParseQueue = () => {
    onOpenParseQueue();
  };

  const handleOpenFooterNotif = () => {
    onOpenFooterNotif();
  };

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const constants = async () => {
    await reqGenConstant({});
    await delay(100);
    await reqCheckUser({});
    await delay(100);
    await reqGetInterviewBody({});
    await delay(100);
    await reqPitch({});
    await delay(100);
    await reqUsers({});
    await delay(100);
    await reqDefTempList({});
    await delay(500);

    await getMeetingType({});
    await delay(500);
  };

  useEffect(() => {
    if (resCheckUser.isSuccess) {
      const data = resCheckUser.data.data;
      if (data.id !== auth?.id) {
        switchToTeamMember(data);
      }
      callViewProfile();
    }
  }, [resCheckUser.isSuccess]);

  const callViewProfile = async () => {
    await delay(100);
    await reqViewProfile({ id: auth?.id });
    await delay(200);
    await reqTeams({ user_id: auth?.id });
  };

  // console.log("profile", profile)
  const switchToTeamMember = async (member: any) => {
    const user = {
      first_name: member?.first_name,
      last_name: member?.last_name,
      email: member?.email,
      user_id: member.id,
      signedUrl: member.signedUrl,
      thumbnail: member.thumbnailSignedUrl,
    };
    setProfile(user);
    // console.log("user ", user)
    dispatch(updateApp({ hasSwitchedAccount: user }));
  };

  useEffect(() => {
    dispatch(loginAuth({ currentProfile: profile }));
  }, [profile]);

  useEffect(() => {
    if (resViewProfile.isSuccess) {
      let data = { ...resViewProfile.data.data };
      if (hasSwitchedAccount !== null) {
        data = { ...hasSwitchedAccount };
      }
      // console.log("data ", data)
      data['name'] =
        data?.name ??
        [data?.first_name, data?.last_name].filter(Boolean).join(' ') ??
        '';
      if (params['*'].includes('profile')) {
        profileConstant(data.user_id || data.id);
      }
      setProfile(data);
    }
  }, [resViewProfile.isSuccess]);

  // console.log("hasSwitchedAccount", hasSwitchedAccount)
  useEffect(() => {
    if (resTeams.isSuccess) {
      const data = resTeams.data.data.data;
      if (data.length > 0) {
        let team_members: any = [];
        data[0].members.map((member: any) => {
          if (member.role !== 'manager') {
            team_members.push(member);
          }
        });
        // setTeamMembers(team_members.filter(Boolean))
        dispatch(updateTeam({ teamMembers: team_members.filter(Boolean) }));
      }
    }
  }, [resTeams.isSuccess]);

  const handleSwitchAccount = async (member: teamMember) => {
    // console.log("has clicked switched account")
    // console.log("member", member)
    if (member) {
      const user = {
        first_name: member.user?.first_name,
        last_name: member.user?.last_name,
        user_id: member.user_id,
        signedUrl: member.signedUrl,
        thumbnail: member.thumbnailSignedUrl,
      };
      // console.log("user ", user)

      dispatch(updateApp({ hasSwitchedAccount: user }));
      const data = {
        account_id: member.user_id,
        manager_id: auth?.id,
      };
      await reqSwitch({ data });
    } else {
      const user = {
        first_name: auth?.first_name,
        last_name: auth?.last_name,
        user_id: auth?.id,
        signedUrl: auth.signedUrl,
        thumbnail: auth.thumbnailSignedUrl,
      };
      dispatch(updateApp({ hasSwitchedAccount: null }));
      const data: any = {
        account_id: auth?.id,
        manager_id: auth?.id,
      };
      await reqSwitch({ data });
    }
  };
  const [reqStatus] = useApprovalStatusMutation();
  const [reqTypes] = useGetTypesMutation();

  const { approvalPgBtn } = useSelector((state: any) => state.approvals);
  const approvalsConstant = async () => {
    navigate('/approvals');
    await reqApprovals({ data: approvalPgBtn });
    await reqStatus({});
    await reqTypes({});
  };

  const [reqCandSource, resCandSource] = useGetCandidateSourceMutation();
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqPinList, resPinList] = usePinListCandidateFilterMutation();
  const [reqFilterList] = useListCandidateFilterMutation();
  const candidatesConstant = async () => {
    navigate(`/candidates/views`);
    dispatch(listCandidates({ candidatePgBtn: initialCandidatePgBtn }));
    await reqCandSource({});
    await reqCandidates({ data: initialCandidatePgBtn });
    await reqPinList(initialCandidatePgBtn);
    await reqFilterList({});
  };

  const profileConstant = async (id: number) => {
    navigate(`/profile/${id}`);
  };

  const [reqJobs, resList] = useListJobsMutation();

  const jobsConstant = async () => {
    navigate('/jobs');
    await reqJobs(initialJobPgBtn);
    dispatch(listJobs({ jobPgBtn: initialJobPgBtn }));
  };

  const [reqMyList, resMyList] = useMyListClientsMutation();

  const clientsConstant = async () => {
    navigate('/clients');
    await reqMyList(initialClientPageBtn);
    dispatch(listClients({ clientPageBtn: initialClientPageBtn }));
    // navigate("/clients")
  };
  const [reqLeads, resLeads] = useListLeadsMutation();

  const leadsConstant = async () => {
    navigate('/leads');
    await reqLeads({ data: initialLeadPgBtn });
    dispatch(listLeads({ leadPgBtn: initialLeadPgBtn }));
  };
  const [reqDeals] = useGetDealsMutation();
  const [reqTopRecruiters] = useGetTopRecruitersMutation();
  const [reqRecruitmentPipelines] = useGetRecruitmentPipelinesMutation();
  const [reqAcquisitionSource] = useGetAcquisitionSourceMutation();
  const [reqActivity] = useActivityListMutation();

  const dashboardConstant = async () => {
    await reqDeals({});
    await reqTopRecruiters({});
    await reqRecruitmentPipelines({});
    await reqAcquisitionSource({});
    await reqActivity({
      data: { target: 'current month', recruiters: null },
    });
  };

  const settingsConstant = async () => {
    dispatch(updateApp({ settingsTabIndex: { id: 0, title: 'Users' } }));
  };
  useEffect(() => {
    if (resSwitch.isSuccess) {
      constants();
      if (params['*'].includes('candidates')) {
        candidatesConstant();
      } else if (params['*'].includes('dashboard')) {
        dashboardConstant();
      } else if (params['*'].includes('jobs')) {
        jobsConstant();
      } else if (params['*'].includes('clients')) {
        clientsConstant();
      } else if (params['*'].includes('leads')) {
        leadsConstant();
      } else if (params['*'].includes('approvals')) {
        approvalsConstant();
      }
      if (params['*'].includes('settings')) {
        callViewProfile();
        settingsConstant();
      }
      // TO DO: ENGAGE starting reqs
      if (params['*'].includes('engage')) {
      }
    }
  }, [resSwitch.isSuccess]);

  const handleNotification = () => {};
  // console.log("hasSwitchedAccount ", hasSwitchedAccount)

  const isDashboard = params['*'] === 'dashboard';
  const isEngage =
    params['*'].includes('engage') ||
    params['*'].includes('leads') ||
    (params['*'].includes('jobs') && !params['*'].includes('clients')) ||
    params['*'].includes('candidates');

  const [reqGetScheduleList] = useGetScheduleListMutation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(updateApp({ isCongrats: false }));
    }, 10000);

    // Clean up the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [isCongrats]);

  useEffect(() => {
    const load = async () => {
      await reqGetScheduleList({});
    };
    load();
  }, []);

  const config = {
    force: 0.8,
    duration: 10000,
    particleCount: 250,
    width: 1600,
    colors: ['#bdde11', '#1c6cdc', '#fce22a', '#fc447c'],
  };

  return {
    config,
    isNavBar,
    handleOpenParseQueue,
    handleOpenFooterNotif,
    isOpenParseQueue,
    onCloseParseQueue,
    isDashboard,
    isEngage,
    isCongrats,
    hasSwitchedAccount,
    first_name,
    setIsNavBar,
    isOpenFooterNotif,
    onCloseFooterNotif,
    handleSwitchAccount,
    handleNotification,
    constants,
    callViewProfile,
    profile,
    // teamMembers,
    auth,
    isTalieShow,
    isBackDrop,
  };
}
