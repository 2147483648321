/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  usePlaceHolderPairMutation,
  useRawListTemplateMutation,
} from 'store/template.slice';

import { useDisclosure, useToast } from '@chakra-ui/react';
import { cleanUpload, uploadList } from 'store/uploads.slice';
import replacePTags from 'utils/replaceTags';

import { useSendEmailContactMutation } from 'store/contact.slice';
import * as Yup from 'yup';
import {
  replacePlaceholders,
  replacePlaceholdersHighlight,
  replaceWithPlaceholders,
} from 'utils/placeholders';

interface EmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  id: number | string;
  subject?: string;
}
export const useEmailsModalServices = ({
  isOpen,
  onClose,
  email,
  id,
  subject,
}: EmailModalProps) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { placehoderPair, templateRawList } = useSelector(
    (state: any) => state.template
  );
  const [templateId, setTemplateId] = useState('');
  const { userList } = useSelector((state: any) => state.user);

  const populateTemplate = (template: any) => {
    try {
      if (template) {
        return {
          value: template?.id,
          label: template?.title,
          subject: template?.subject,
          body: template?.body,
          attachments: template?.attachments,
        };
      } else {
        return null as any;
      }
    } catch (e) {
      return null;
    }
  };

  const [candidateTemplate, setCandidateTemplate] = useState(
    templateRawList.filter((template: any) => template?.id === templateId)[0] ??
      null
  );
  const { uploaded } = useSelector((state: any) => state.uploads);

  const [reqList] = useRawListTemplateMutation();

  const [reqEmail, resEmail] = useSendEmailContactMutation();
  const [reqPair] = usePlaceHolderPairMutation();

  const [placehoder, setPlaceHoders] = useState(placehoderPair);
  const [quilValue, setquilValue] = useState(candidateTemplate?.body || '');
  const [template, setTemplate] = useState([]);
  const [ccBcc, setCcBcc] = useState([]);
  const [bcc, setBcc] = useState([]);

  const [bccValue, setBccValue] = useState([]);
  const [ccValue, setCcValue] = useState([]);

  const initialValues = {
    subject: subject || candidateTemplate?.subject,
    body: candidateTemplate?.body,
    to: email,
    cc: [] as any,
    bcc: [] as any,
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [tokenLink, setTokenLink] = useState('');
  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });

  const placholders = (text: string) => {
    return replacePlaceholders(text, placehoder);
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      subject: Yup.string().required('Subject is required.'),
      body: Yup.string().required('Body is required.'),
      to: Yup.string()
        .email('Invalid email format')
        .required('Email is required.'),
    })
  );

  const replyEmail = (data: any) => {
    data['attachments'] = uploaded || [];
    data['att_template'] = attachments || [];

    let cc: any = [];
    if (ccValue && ccValue?.length > 0) {
      ccValue.forEach((item: any) => {
        cc.push(item.value);
      });
    }
    let bcc: any = [];
    if (bccValue && bccValue?.length > 0) {
      bccValue.forEach((item: any) => {
        bcc.push(item.value);
      });
    }
    const newParam = {
      to: data.to,
      bcc: bcc,
      cc: cc,
      subject: placholders(data.subject),
      body: placholders(data.body?.replace(/<div><\/div>/g, '<br />')),
      att_template: attachments || [],
    };

    reqEmail({ data: newParam, id });
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      const data = {
        contact_id: id,
      } as any;
      reqPair(data);
    }
  }, [isOpen]);

  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);

  useEffect(() => {
    reqList({ id: 3 });
    dispatch(cleanUpload({ attachments: [] }));
    dispatch(cleanUpload({ uploaded: [] }));

    let ccOption = [] as any;
    Object.values(userList).forEach((item: any) => {
      ccOption.push({
        label: `${[item.first_name, item.last_name]
          .filter(Boolean)
          .join(' ')} <${item.email}>`,
        value: item.email,
      });
    });
    setCcBcc(ccOption);
    setBcc(ccOption);
  }, [id]);

  useEffect(() => {
    if (resEmail.isError) {
      const error: any = resEmail.error;
      try {
        const message =
          error?.data?.details || error?.data?.data?.message || 'Unkown error';
        if (error?.data?.data?.type === 'redirect') {
          setTokenLink(error?.data?.data?.url);
        }
        setErrorMsg(message);
        openAlert();
      } catch (e) {
        console.error('error', e);
      }
    } else {
      setErrorMsg('');
    }
  }, [resEmail.isError]);

  useEffect(() => {
    if (resEmail.isSuccess) {
      toast({
        title: 'Email sent',
        description: 'We have sent your email',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });

      dispatch(uploadList({ uploaded: [], attachments: [] }));
      onClose();
    }
  }, [resEmail.isSuccess]);

  useEffect(() => {
    let option: any = [];
    templateRawList.forEach((item: any) => {
      option.push({
        value: item.id,
        label: item.title,
        subject: item.subject,
        body: item.body,
        attachments: item.attachments,
      });
    });
    setTemplate([
      ...option,
      {
        value: null,
        label: 'Create New Template',
        subject: '',
        body: '',
        attachments: null,
      },
    ]);
  }, [templateRawList]);

  const templateCallback = (setFieldValue: any) => {
    reqList({ id: 1 }).then((res: any) => {
      const data = res.data.data;

      const highestIdIndex = data.reduce(
        (maxIndex: any, item: any, index: any, array: any) =>
          item.id > array[maxIndex].id ? index : maxIndex,
        0
      );

      const temp = data[highestIdIndex];

      setCandidateTemplate(populateTemplate(temp));
      setFieldValue('body', temp.body);
      setFieldValue('subject', temp.subject);
    });
  };

  return {
    initialValues,
    replyEmail,
    validationSchema,
    ccBcc,
    populateTemplate,
    clientTemplate: candidateTemplate,
    template,
    attachments,
    setAttachments,
    setquilValue,
    placholders,
    bcc,
    quilValue,
    isLoading: resEmail.isLoading,
    setTemplateId,
    handleClose,
    previewOpen,
    setPreviewOpen,
    isOpenAlert,
    onCloseAlert,
    openAlert,
    tokenLink,
    errMsg,
    bccValue,
    setBccValue,
    ccValue,
    setCcValue,
    templateCallback,
    placehoder,
    templateId,
  };
};
