import { Box, Flex, Tag } from '@chakra-ui/react';
import Button from 'Library/Button';

interface BultkActionProps {
  totalSelected: number;
  onMarkInActive: () => void;
}
export default function BulkAction({
  totalSelected,
  onMarkInActive,
}: BultkActionProps) {
  return (
    <>
      <Tag
        display="flex"
        borderRadius="4px"
        variant="subtle"
        color="#545454"
        bg="#FAF7FF"
        fontWeight="regular"
        fontSize="md"
        border="1px solid #AE8FE2"
        height="32px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex gap={2} fontSize="14px" alignItems="center">
          <Box color="#6930CA" fontSize="sm">
            {totalSelected}
          </Box>{' '}
          {totalSelected > 1 ? 'items' : 'item'} selected
        </Flex>
      </Tag>
      <Button variant="basic" leftIcon="ban" size="sm" onClick={onMarkInActive}>
        Mark as Inactive
      </Button>
    </>
  );
}
