import { useState } from 'react';
import { Node, NodeViewProps, mergeAttributes } from '@tiptap/core';
import { nodeInputRule } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { useSelector } from 'react-redux';

const inputRegex = /\{\{(.*?)\}\}/;
// Define the Placeholder Node Extension as before
const PlaceholderNode = Node.create({
  name: 'placeholderNode',

  inline: true,
  group: 'inline',
  atom: true, // Makes it behave like an atomic element

  addAttributes() {
    return {
      value: {
        default: '',
      },
      variableName: {
        parseHTML: (element) => element.getAttribute('data-variable'),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-variable]',
        getAttrs: (element) => {
          const match = element.getAttribute('data-variable');
          return match ? { variableName: match.replace(/{{|}}/g, '') } : null;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-placeholder': true, class: 'placeholder-node' },
        HTMLAttributes
      ),
      HTMLAttributes.variableName || '',
    ];
  },
  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ];
  },
  addNodeView() {
    return ReactNodeViewRenderer(PlaceholderComponent);
  },
});

const containsBlockElements = (html: string) => {
  const blockElements = [
    '<div',
    '<p',
    '<h1',
    '<h2',
    '<h3',
    '<h4',
    '<h5',
    '<h6',
    '<ul',
    '<ol',
    '<li',
    '<blockquote',
    '<pre',
  ];
  if (!html) return false;
  return blockElements?.some((tag) => html?.includes(tag));
};
function PlaceholderComponent(props: NodeViewProps) {
  const placeholderValues = useSelector(
    (state: any): Record<string, string> => state.template.placehoderPair
  );
  const variableName = props.node?.attrs?.variableName;
  const isBlockContent = containsBlockElements(
    placeholderValues?.[variableName] ?? ''
  );
  // const isBlockContent = true;
  const [isSelected, setIsSelected] = useState(false);

  // Register node selection and deselection
  props.editor.on('selectionUpdate', () => {
    const { from, to } = props?.editor?.state?.selection;
    const nodePos = props?.getPos();
    setIsSelected(from <= nodePos && to > nodePos);
  });
  return (
    <NodeViewWrapper
      contentEditable={false}
      className={isSelected ? 'react-component selected' : 'react-component'}
      as={isBlockContent ? 'div' : 'span'}
      style={{
        fontFamily: 'verdana',
        color: '#718096',
        fontSize: '14px',
        backgroundColor: '#E7EDF4',
        padding: '0 4px',
        borderRadius: '4px',
        border: isSelected ? '2px solid #718096' : '2px solid #E7EDF4',
      }}
      dangerouslySetInnerHTML={{
        __html: placeholderValues[variableName] || '',
      }}
    ></NodeViewWrapper>
  );
}
export default PlaceholderNode;
