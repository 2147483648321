import { apiSlice } from 'store/api.slice';
import { ApiResponseType } from './type';

export const constantAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchCountries: builder.query({
      query: () => ({
        url: `/constants/countries`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Countries'],
      transformResponse: (result: ApiResponseType) => {
        return result.data;
      },
    }),
    fetchLeadSources: builder.query({
      query: () => ({
        url: `/constants/lead-source`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Lead-Sources'],
      transformResponse: (result: ApiResponseType) => {
        return result.data;
      },
    }),
    fetchJobSpecifications: builder.query({
      query: () => ({
        url: '/constants/job-specifications',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Job-Specifications'],
      transformResponse: (result: ApiResponseType) => {
        return result.data;
      },
    }),
  }),
});

export const {
  useFetchCountriesQuery,
  useFetchLeadSourcesQuery,
  useFetchJobSpecificationsQuery,
} = constantAPI;
