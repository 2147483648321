import { Tooltip } from 'react-tooltip';
import moment from 'moment';

import {
  Box,
  Checkbox,
  Flex,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  Td,
  Tr,
  UnorderedList,
  MenuItem,
  Badge,
  useDisclosure,
  Tag,
  VStack,
} from '@chakra-ui/react';

import FAIcon from 'components/lib/FAIcon';
import Button from 'Library/Button';

import { useDispatch, useSelector } from 'react-redux';
import {
  getCandidate,
  listCandidates,
  useGetCandidatesMutation,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import { useParams } from 'react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetJobsMutation } from 'store/jobs.slice';
import CandidateJobList from '../../components/CandidateJobList';
import SubmitToClientModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/SubmitToClient';
import {
  CandidateData,
  candidateEmail,
  candidateJobs,
  isCheckWithRowType,
} from 'types';
// import linkedIn from '@assets/images/linkedin.svg';
import linkedIn from '../../../../../assets/images/linkedin.svg';
import toUrl from 'utils/toUrl';
import { getDaysRemaining } from 'utils/getDaysRemaining';
import EmailsModal from 'components/app/Global/Email/Modal';
import ChangeStatusModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/ChangeStatus';
import { usePinListCandidateFilterMutation } from 'store/candidatefilter.slice';

interface jobselectInt {
  id: number;
  type: string;
  assoc_id?: number;
  reject?: string;
  candidate?: CandidateData;
}

interface interviewDataInt {
  counter: number;
  id: number;
  job_id: number;
  meeting_date: string;
  meeting_end: string;
  meeting_start: string;
  meeting_timezone: string;
  status: number;
}

interface DynamicCandidateTRInterface {
  candidate: CandidateData;
  handleCheck: any;
  isCheck: any;
  handleClick: (candidate: any) => void;
  associateToJob: any;
  addTagClick: any;
  editCandidate: any;
  handleDeleteCandidate: (e: any) => void;
  handleHideCandidate: (e: any) => void;
  HandleMenuItem?: any;
  fromJob?: boolean;
  hasStatus?: boolean;
  isCheckWithJob?: any;
  isCheckWithRow?: isCheckWithRowType[];
  fromGroupByJobs?: boolean;
  key?: any;
  jobTitle?: any;
  clientName?: string;
  jobID?: any;
  clientID?: string;
  statusChangeSuccessCb: (e: any) => void;
  hasInterview?: boolean;
  columns?: string[];
  groupItem?: any;
}
const DynamicCandidateTR = ({
  candidate,
  columns,
  handleCheck,
  isCheck,
  handleClick,
  associateToJob,
  addTagClick,
  editCandidate,
  handleDeleteCandidate,
  handleHideCandidate,
  HandleMenuItem,
  fromJob = false,
  hasStatus = false,
  isCheckWithJob = [],
  isCheckWithRow = [],
  fromGroupByJobs = false,
  key,
  jobTitle,
  clientName,
  jobID,
  clientID,
  statusChangeSuccessCb,
  hasInterview = false,
  groupItem,
}: DynamicCandidateTRInterface) => {
  let emails = candidate?.emails?.filter(
    (email: candidateEmail) => email.primary
  );

  let contacts = candidate?.contact?.filter((contact: any) => contact.primary);

  emails = emails.length === 0 ? candidate?.emails : emails;
  const param = useParams();
  const dispatch = useDispatch();
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqPinFilterListUpdate] = usePinListCandidateFilterMutation();

  const { group } = useSelector((state: any) => state.candidateFilters);

  const jobsPopulate = (data: any[], id: any) => {
    const memoizedList = (key: number, less?: boolean, color?: boolean) => {
      return (
        <UnorderedList key={`tr-ul-${key}`}>
          {data?.map((job: any, index: number) => {
            return (
              <>
                {(less ? index < key : index > key) && (
                  <ListItem key={`memoized-list-${key}-${index}`}>
                    <Link
                      href={`/jobs/${job?.job_id}/details`}
                      target="_blank"
                      color={color && '#fff'}
                    >
                      {job?.title}
                    </Link>
                  </ListItem>
                )}
              </>
            );
          })}
        </UnorderedList>
      );
    };

    if (data.length > 3) {
      return (
        <>
          {memoizedList(3, true)}
          <Badge
            id={`jobTooltip${id}`}
            colorScheme="purple"
            border="0.5px solid #44337a"
            borderRadius="4px"
            height="fit-content"
          >
            + {data.length - 3}
          </Badge>
          <Tooltip anchorSelect={`#jobTooltip${id}`} place="bottom" clickable>
            {memoizedList(2, false, true)}
          </Tooltip>
        </>
      );
    }
    return memoizedList(-1);
  };

  const tagsPopulate = (data: string[], id: any) => {
    if (data.length > 3) {
      return (
        <>
          {data.slice(0, 3).join(', ')}
          <Badge
            id={`tagTooltip${id}`}
            colorScheme="purple"
            border="0.5px solid #44337a"
            borderRadius="4px"
            height="fit-content"
          >
            + {data.length - 3}
          </Badge>
          <Tooltip anchorSelect={`#tagTooltip${id}`} place="bottom">
            <Box maxWidth="200px" width="inherit" height="max-content">
              {data.slice(3).join(', ')}
            </Box>
          </Tooltip>
        </>
      );
    }
    return data.join(', ');
  };

  const jobStatus = (candidate: any) => {
    try {
      if (candidate?.jobs?.length > 0) {
        const data: any = candidate?.jobs[0];
        return <Tag variant="outline">{data?.sub?.sub_status}</Tag>;
      } else {
        return '--';
      }
    } catch (e) {
      return 'Error';
    }
  };

  const {
    isOpen: isOpenSendEmail,
    onOpen: onOpenSendEmail,
    onClose: onCloseSendEmail,
  } = useDisclosure();

  const openSendEmailClick = async (candidate: any) => {
    const job_id =
      candidateDataJobId ||
      candidate?.jobs?.[0]?.job_id ||
      param?.jobsId ||
      null;

    const data = {
      candidate_id: candidate?.id,
      job_id,
      // job_id: fromJob || fromGroupByJobs ? job_id : null,
    } as any;
    await Promise.all([reqPair(data), reqGetCandidate({ id: candidate?.id })]);

    if (candidate?.jobs.length > 0) {
      reqjob({ id: candidate?.jobs?.[0]?.id });
    }

    setJobId(job_id);

    dispatch(
      getCandidate({
        candidateDataJobId: job_id,
      })
    );
    onOpenSendEmail();
  };

  const [jobList, setJobList] = useState([]);
  const [actionType, steActionType] = useState('');
  const [jobId, setJobId] = useState(null);
  const [assocId, setAssocId] = useState(0);
  const [assocIdList, setAssocIdList] = useState([0]);
  const [mainStatus, setMainStatus] = useState(0);
  const [subStatus, setSubStatus] = useState(0);
  const [candidateId, setCandidateId] = useState(0);
  const [interviewData, setInterviewData] = useState<interviewDataInt>(null);

  const interviewStatus = {
    1: {
      scheme: 'purple',
      color: '#6930CA',
      label: 'Scheduled',
    },
    2: {
      scheme: 'green',
      color: '#38B17A',
      label: 'Completed',
    },
  } as any;

  const { candidateDataJobId, candidateOthers } = useSelector(
    (state: any) => state.candidates
  );

  const [isGroupByJob, setIsGroupByJob] = useState(false);

  // replaced candidateDataJobId with jobID since candidateDataJobId may not correspond
  // the actual job id of the candidate
  const [jobDetails, setJobDetails] = useState<any>(
    // candidateDataJobId
    jobID ? candidate?.jobs.filter((job: any) => job.job_id === jobID)[0] : null
  );
  // const jobDetails: any =
  //   candidateDataJobId &&
  //   candidate?.jobs.filter((job: any) => job.id === candidateDataJobId)[0];

  useEffect(() => {
    if (jobID) {
      // commented this because it continues to update candidateDataJobId.
      // disrupts candidateDataJobId especially if other accordions are opened
      // dispatch(getCandidate({ candidateDataJobId: jobID }))
      const details = candidate?.jobs.filter(
        (job: any) => job.job_id === Number(jobID)
      )[0];

      setJobDetails(details);
    }
  }, [jobID]);

  useEffect(() => {
    setIsGroupByJob(
      candidateOthers &&
        candidateOthers.length > 0 &&
        candidateOthers[0].group_by_job
    );
  }, [candidateOthers]);

  const { filter } = useSelector((state: any) => state.candidateFilters);

  useEffect(() => {
    if (filter) {
      setIsGroupByJob(true);
    }
  }, [filter]);

  const [reqjob] = useGetJobsMutation();
  const [reqGetCandidate] = useGetCandidatesMutation();
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  const {
    isOpen: isOpenJobList,
    onClose: onCloseJobList,
    onOpen: onOpenJobList,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenSubmit,
    onClose: onCloseSubmit,
    onOpen: onOpenSubmit,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure();

  const statusChangeSuccess = async (e: any) => {
    // setIsBulkAction(false);
    //to avoid multiple calls from the api
    if (!group) {
      reqCandidates({ data: candidatePgBtn });
    }
    reqPinFilterListUpdate({})
    statusChangeSuccessCb(e);
  };

  const onJobSelect = (data: jobselectInt) => {
    if (data.type === 'submit' || data.type === 'interview') {
      reqjob({ id: data.id }).then((res: any) => {
        setJobId(data.id);
        if (data.type === 'submit') {
          onOpenSubmit();
        }
      });
    } else if (data.type === 'status') {
      candidate?.jobs.map((job: candidateJobs) => {
        if (Number(job?.job_id) == data.id) {
          setAssocId(Number(job?.assoc_id));
          setMainStatus(Number(job?.status.id));
          setSubStatus(Number(job?.sub.id));
          onOpenChangeStatus();
        }
      });
    }
  };

  const changeStatus = async (candidate: any) => {
    steActionType('status');
    dispatch(getCandidate({ candidateData: candidate }));
    const jobs_length = candidate?.jobs?.length;
    if (isGroupByJob) {
      onJobSelect({
        id: Number(jobID),
        type: 'status',
        assoc_id: jobDetails?.assoc_id,
      });
    } else {
      if (jobs_length > 0) {
        let jobs = [] as any;
        let job_id = null as any;
        let assoc = null as any;
        await Promise.all(
          candidate?.jobs?.map(async (item: any) => {
            const param = {
              job: {
                ...item,
              },
            };
            jobs.push(param);
            job_id = item.id;
            assoc = item.assoc_id;
          })
        );
        setJobList(jobs);
        if (jobs.length === 1) {
          onJobSelect({
            id: Number(job_id),
            type: 'status',
            assoc_id: assoc,
          });
        } else {
          onOpenJobList();
        }
      }
    }
  };

  const submitToClient = async (candidateData: any) => {
    let jobs = [] as any;
    let job_id = null as any;
    let assoc = null as any;
    steActionType('submit');
    if (isGroupByJob) {
      onJobSelect({
        id:
          jobID ||
          Number(jobDetails?.job?.job_id) ||
          candidateData?.jobs?.[0]?.job_id ||
          candidateDataJobId,
        type: 'submit',
        assoc_id: jobDetails?.assoc_id || candidateData?.jobs?.[0]?.assoc_id,
        reject: jobDetails?.sub?.target,
      });
    } else {
      await Promise.all(
        candidateData?.jobs?.map(async (item: any) => {
          if (param?.jobsId) {
            if (
              (item.candidate_status_id === 2 || item?.status?.id === 2) &&
              item?.job_id == param?.jobsId
            ) {
              // if (item?.job) {
              jobs.push(item);
              // job_id = item?.job?.id;
              job_id = item?.job_id;
              // assoc = item;
              assoc = item?.assoc_Id;
              // }
            }
          } else {
            if (item.candidate_status_id === 2 || item?.status?.id === 2) {
              // if (item?.job) {
              jobs.push(item);
              // job_id = item?.job?.id;
              job_id = item?.job_id;
              // assoc = item;
              assoc = item?.assoc_Id;
              // }
            }
          }
        })
      );
      setJobList(jobs);
      if (jobs.length === 1) {
        onJobSelect({
          id: Number(job_id),
          type: 'submit',
          assoc_id: assoc?.id,
          reject: assoc?.sub?.target,
        });
      } else {
        onOpenJobList();
      }
    }
  };

  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure();

  const onEmailClick = async (candidate: any) => {
    const data = {
      candidate_id: candidate?.id,
      job_id: candidate?.job_id || param?.jobsId || null,
    } as any;
    setJobId(data.job_id);
    setCandidateId(data.candidate_id);
    reqPair(data);
    onOpenEmail();
    if (candidate?.jobs?.length > 0) {
      reqjob({ id: candidate?.jobs?.[0]?.id });
    }
  };

  useEffect(() => {
    // console.log({ candidate, jobID });
    if (hasInterview) {
      const interview = candidate?.jobs.find(
        (item: any) => item.job_id == jobID
      ) as any;
      setInterviewData(interview?.interview);
    }
  }, [candidate, jobID, hasInterview, columns]);

  // console.log("candidate?.jobs?.[0]", candidate?.jobs?.[0])

  // Candidate Details
  const CandidateDetails = () => {
    return (
      <Flex
        onClick={() => handleClick(candidate)}
        flexDirection="column"
        justifyContent="center"
        width="300px"
        minH="85px"
      >
        <Box
          fontWeight="bold"
          fontSize="14px"
          color="default.primarytext"
          isTruncated
          textTransform="capitalize"
        >
          <Flex gap={2}>
            {[candidate.first_name, candidate.last_name].join(' ')}{' '}
            {candidate?.linkedin_url != '' &&
              candidate?.linkedin_url != null && (
                <Link
                  target="_blank"
                  href={toUrl(candidate?.linkedin_url)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={linkedIn}
                    alt="LinkedIn Logo"
                    style={{
                      width: '15px',
                      height: '15px',
                      objectFit: 'contain',
                    }}
                  />
                </Link>
              )}
          </Flex>
        </Box>
        {candidate.latest_job_title && (
          <Box fontSize="14px" color="default.secondarytext" isTruncated>
            {candidate.latest_job_title}
          </Box>
        )}
        {candidate.latest_employer && (
          <Box fontSize="14px" color="default.secondarytext" isTruncated>
            {candidate.latest_employer}
          </Box>
        )}
        {hasInterview && interviewData?.counter > 0 && (
          <Box fontSize="14px" color="default.secondarytext" isTruncated>
            <Badge>Interview # {interviewData?.counter}</Badge>
          </Box>
        )}
      </Flex>
    );
  };
  // Contact
  const Contact = () => {
    return (
      <Box
        fontSize="14px"
        color="default.primarytext"
        sx={{ w: '200px', textWrap: 'balance' }}
      >
        <VStack spacing={1} align="stretch">
          <Box onClick={() => onEmailClick(candidate)}>
            <Link href="#">{emails?.[0]?.email}</Link>
          </Box>
          <Box>
            <Link href={`tel:${contacts[0]?.number}`}>
              {contacts[0]?.number}
            </Link>
          </Box>
        </VStack>
      </Box>
    );
  };
  // Location
  const Location = () => {
    return (
      <Box
        fontSize="14px"
        color="default.primarytext"
        sx={{ w: '200px', textWrap: 'balance' }}
      >
        {/* {locationParse(candidate)} */}
        {[candidate?.city, candidate?.state_province]
          .filter(Boolean)
          .join(', ')}
      </Box>
    );
  };
  // Resume Update on
  const ResumeUpdateOn = () => {
    return (
      <>
        {candidate.resume_update_on ? (
          <Box fontSize="14px" color="default.primarytext" w="200px">
            {moment.utc(candidate.resume_update_on).format('MM/DD/YYYY')}
          </Box>
        ) : (
          <Box width="200px"></Box>
        )}
      </>
    );
  };
  // Work Status
  const WorkStatus = () => {
    return (
      <Box
        fontSize="14px"
        textTransform="capitalize"
        color="default.primarytext"
        w="200px"
      >
        {candidate.work_status?.status}
      </Box>
    );
  };
  // Last Activity
  const LastActivity = () => {
    return (
      <>
        {candidate.last_activity ? (
          <Box fontSize="14px" color="default.primarytext" w="200px">
            {moment.utc(candidate.last_activity).format('MM/DD/YYYY')}
          </Box>
        ) : (
          <Box width="200px"></Box>
        )}
      </>
    );
  };
  // Associated Jobs
  const AssociatedJobs = () => {
    return (
      <>
        {candidate.jobs?.length > 0 ? (
          <Flex
            fontSize="14px"
            color="default.primarytext"
            whiteSpace="normal"
            width="200px"
            gap="10px"
            alignItems="flex-end"
            sx={{ w: '200px', textWrap: 'balance' }}
          >
            {candidate.jobs?.length > 0 &&
              jobsPopulate(candidate.jobs, candidate.id)}
          </Flex>
        ) : (
          <Flex width="200px"></Flex>
        )}
      </>
    );
  };
  // Tags
  const Tags = () => {
    return (
      <>
        {candidate.tags?.length > 0 ? (
          <Flex
            fontSize="14px"
            color="default.primarytext"
            whiteSpace="normal"
            width="200px"
            gap="10px"
            alignItems="flex-end"
            sx={{ w: '200px', textWrap: 'balance' }}
          >
            <>
              {tagsPopulate(candidate.tags, candidate.id)}
              <Box color="rgb(105 48 202 / 70%)">
                <FAIcon iconName="tags" />
              </Box>
            </>
          </Flex>
        ) : (
          <Flex width="200px"></Flex>
        )}
      </>
    );
  };
  // Distance
  const Distance = () => {
    return (
      <>
        {candidate?.location_distance &&
        candidate?.location_distance?.distance != null &&
        candidate?.location_distance?.location ? (
          <>
            <Box
              fontSize="14px"
              color="default.secondarytext"
              isTruncated
              // textAlign="center"
              width="200px"
            >
              {`${candidate?.location_distance?.location}`}
            </Box>

            <Box
              fontSize="14px"
              color="primary.600"
              isTruncated
              fontWeight="600"
              // textAlign="center"
              width="200px"
            >
              {/* Rounds up distance */}
              {`${Math.ceil(
                parseFloat(String(candidate?.location_distance?.distance))
              )} miles`}
            </Box>
          </>
        ) : (
          <Box width="200px"></Box>
        )}
      </>
    );
  };
  // Submitted on
  const SubmittedOn = () => {
    // const job = candidate?.jobs.find(
    //   (item: any) => item.job_id == jobID
    // ) as any;
    return (
      <>
        {candidate?.submitted_on ? (
          <>
            <Box
              fontSize="14px"
              color="default.secondarytext"
              isTruncated
              // textAlign="center"
              width="200px"
            >
              {moment.utc(candidate?.submitted_on).format('MM/DD/YYYY')}
            </Box>
            <Box
              fontSize="14px"
              color="primary.600"
              isTruncated
              fontWeight={700}
              // textAlign="center"
              width="200px"
            >
              {/* add the date here */}
              {`${getDaysRemaining(
                new Date(candidate?.submitted_on),
                new Date()
              )}`}{' '}
              days
              {/* {`${candidate?.location_distance?.location} miles`} */}
            </Box>
          </>
        ) : (
          <Box width="200px"></Box>
        )}
      </>
    );
  };

  // Interview Status
  const InterviewStatus = () => {
    // const job = candidate?.jobs.find(
    //   (item: any) => item.job_id == jobID
    // ) as any;
    return (
      <>
        {interviewStatus[candidate?.interview?.status]?.label ? (
          <Badge
            ml={4}
            colorScheme={
              interviewStatus[candidate?.interview?.status]?.scheme || 'green'
            }
            sx={{
              textTransform: 'capitalize',
              p: '4px 8px',
              fontSize: '14px',
              borderRadius: '2px',
              color: interviewStatus[candidate?.interview?.status]?.color,
              width: 'fit-content',
            }}
          >
            {interviewStatus[candidate?.interview?.status]?.label ||
              'Undefined'}
          </Badge>
        ) : (
          <Box width="200px"></Box>
        )}
      </>
    );
  };
  // Interview Date
  const InterviewDate = () => {
    // const job = candidate?.jobs.find(
    //   (item: any) => item.job_id == jobID
    // ) as any;
    return (
      <>
        {candidate?.interview?.counter > 0 ? (
          <Box textAlign="center">
            <Box isTruncated mb="3px">
              <Badge
                sx={{
                  borderRadius: '2px',
                  p: '4px 8px',
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#2B2D42',
                  textTransform: 'capitalize',
                }}
              >
                Interview # {candidate?.interview?.counter}
              </Badge>
            </Box>
            <Link fontSize="14px" isTruncated textAlign="center" mb="3px">
              {moment(candidate?.interview?.meeting_date).format('MM/DD/YYYY')}
            </Link>

            <Box
              fontSize="14px"
              color="default.secondarytext"
              isTruncated
              textAlign="center"
            >
              {moment(candidate?.interview?.meeting_start, 'HH:mm:ss').format(
                'h:mm A'
              )}{' '}
              -{' '}
              {moment(candidate?.interview?.meeting_end, 'HH:mm:ss').format(
                'h:mm A'
              )}{' '}
              {candidate?.interview?.meeting_timezone}
            </Box>
          </Box>
        ) : (
          <Box width="200px"></Box>
        )}
      </>
    );
  };

  // Offered Date/Age
  const OfferedDate = () => {
    // const job = candidate?.jobs.find(
    //   (item: any) => item.job_id == jobID
    // ) as any;
    return (
      <>
        {candidate?.offered_date ? (
          <>
            <Box
              fontSize="14px"
              color="default.secondarytext"
              isTruncated
              // textAlign="center"
              width="200px"
            >
              {moment.utc(candidate?.offered_date).format('MM/DD/YYYY')}
            </Box>
            <Box
              fontSize="14px"
              color="primary.600"
              isTruncated
              // textAlign="center"
              width="200px"
            >
              {/* add the date here */}
              {`${getDaysRemaining(
                new Date(candidate?.offered_date),
                new Date()
              )}`}
              {/* {`${candidate?.location_distance?.location} miles`} */}
            </Box>
          </>
        ) : (
          <Box width="200px"></Box>
        )}
      </>
    );
  };

  // Placement Date
  const PlacementDate = () => {
    // const job = candidate?.jobs.find(
    //   (item: any) => item.job_id == jobID
    // ) as any;
    return (
      <>
        {candidate?.placement_form?.placement_date ? (
          <>
            <Box
              fontSize="14px"
              color="default.secondarytext"
              isTruncated
              // textAlign="center"
              width="200px"
            >
              {moment
                .utc(candidate?.placement_form?.placement_date)
                .format('MM/DD/YYYY')}
            </Box>
          </>
        ) : (
          <Box width="200px"></Box>
        )}
      </>
    );
  };

  // Candidate Start Date
  const CandidateStartDate = () => {
    // const job = candidate?.jobs.find(
    //   (item: any) => item.job_id == jobID
    // ) as any;
    return (
      <>
        {candidate?.placement_form?.candidate_start_date ? (
          <>
            <Box
              fontSize="14px"
              color="default.secondarytext"
              isTruncated
              // textAlign="center"
              width="200px"
            >
              {moment
                .utc(candidate?.placement_form?.candidate_start_date)
                .format('MM/DD/YYYY')}
            </Box>
          </>
        ) : (
          <Box width="200px"></Box>
        )}
      </>
    );
  };

  // Last Messaged on
  const LastMessagedOn = () => {
    return (
      <>
        {candidate?.last_messaged_on ? (
          <>
            <Box
              fontSize="14px"
              color="default.secondarytext"
              isTruncated
              textAlign="center"
              width="200px"
            >
              {moment.utc(candidate.last_messaged_on).format('MM/DD/YYYY')}
            </Box>
          </>
        ) : (
          <Box width="200px"></Box>
        )}
      </>
    );
  };

  // Last Called on
  const LastCalledOn = () => {
    return (
      <>
        {candidate?.last_called_on ? (
          <>
            <Box
              fontSize="14px"
              color="default.secondarytext"
              isTruncated
              textAlign="center"
              width="200px"
            >
              {moment.utc(candidate.last_called_on).format('MM/DD/YYYY')}
            </Box>
          </>
        ) : (
          <Box width="200px"></Box>
        )}
      </>
    );
  };

  const [dynamicCol, setDynamicCol] = useState<any>([
    <CandidateDetails />,
    <Contact />,
    <Location />,
    <ResumeUpdateOn />,
    <WorkStatus />,
    <LastActivity />,
    <AssociatedJobs />,
    <Tags />,
  ]);

  const memoizedGetColumns = useCallback(() => {
    const newColumns = [] as any;
    columns.map((col: string) => {
      switch (col) {
        case 'candidate_details':
          newColumns.push(<CandidateDetails />);
          break;
        case 'contact':
          newColumns.push(<Contact />);
          break;
        case 'location':
          newColumns.push(<Location />);
          break;
        case 'resume_update_on':
          newColumns.push(<ResumeUpdateOn />);
          break;
        case 'work_status':
          newColumns.push(<WorkStatus />);
          break;
        case 'last_activity':
          newColumns.push(<LastActivity />);
          break;
        case 'associated_jobs':
          newColumns.push(<AssociatedJobs />);
          break;
        case 'tags':
          newColumns.push(<Tags />);
          break;
        case 'distance':
          newColumns.push(<Distance />);
          break;
        case 'submitted_on':
          newColumns.push(<SubmittedOn />);
          break;
        case 'interview_status':
          newColumns.push(<InterviewStatus />);
          break;
        case 'interview_date':
          newColumns.push(<InterviewDate />);
          break;
        case 'offered_date':
          newColumns.push(<OfferedDate />);
          break;
        case 'placement_date':
          newColumns.push(<PlacementDate />);
          break;
        case 'candidate_start_date':
          newColumns.push(<CandidateStartDate />);
          break;
        case 'last_messaged_on':
          newColumns.push(<LastMessagedOn />);
          break;
        case 'last_called_on':
          newColumns.push(<LastCalledOn />);
          break;
        default:
          break;
      }
    });
    setDynamicCol(newColumns);
  }, [setDynamicCol, columns]);

  useEffect(() => {
    memoizedGetColumns();
  }, [memoizedGetColumns]);

  return (
    <>
      <Tr
        key={`candidate-tr-${key}-${candidate.id}`}
        _hover={{
          bg: '#f8f9fa',
        }}
        cursor="pointer"
      >
        {dynamicCol.map((col: any, index: number) => {
          if (index == 0) {
            return (
              <Td
                position="sticky"
                left={0}
                bg="white"
                boxShadow="inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)"
                _hover={{
                  bg: '#f8f9fa',
                }}
                zIndex={1}
              >
                <Flex gap="30px">
                  <Checkbox
                    onChange={(e: any) => {
                      !fromGroupByJobs
                        ? handleCheck(e, candidate?.rowId)
                        : handleCheck(e, candidate?.jobs?.[0]?.job_id);
                    }}
                    isChecked={
                      fromJob
                        ? isCheck.includes(candidate?.id)
                        : !fromGroupByJobs || fromJob
                        ? isCheckWithRow.some(
                            (obj: any) =>
                              obj.id === candidate?.id &&
                              obj.rowId === candidate?.rowId
                          )
                        : isCheckWithJob.some(
                            (obj: any) =>
                              obj.id === candidate?.id &&
                              obj.job_id === candidate?.jobs?.[0]?.job_id
                          )
                    }
                    id={candidate.id.toString()}
                    key={candidate.id}
                    colorScheme="purple"
                  />

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    gap="20px"
                  >
                    {col}
                    <Menu>
                      <MenuButton>
                        <Button
                          htmlType="menu"
                          variant="ghost"
                          iconName="ellipsis-h"
                          iconSize="xl"
                        />
                      </MenuButton>
                      {!fromJob ? (
                        <MenuList
                          fontSize="sm"
                          sx={{
                            position: 'relative',
                            left: '21%',
                          }}
                        >
                          <MenuItem>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="comment-dots" />
                              <Box ml={12} cursor="pointer">
                                Send SMS
                              </Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem
                            onClick={() => openSendEmailClick(candidate)}
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="envelope" />
                              <Box ml={12} cursor="pointer">
                                Send Email
                              </Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem onClick={() => associateToJob(candidate)}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="eye" />
                              <Box ml={12} cursor="pointer">
                                Associate to Job
                              </Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                              onClick={async () => {
                                reqGetCandidate({ id: candidate.id });
                                submitToClient(candidate);
                              }}
                            >
                              <FAIcon iconName="clipboard" />
                              <Box ml={12} cursor="pointer">
                                Submit to Client
                              </Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem onClick={() => addTagClick(candidate.id)}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="tags" />
                              <Box ml={12} cursor="pointer">
                                Add Tags
                              </Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem
                            onClick={() => changeStatus(candidate)}
                            isDisabled={candidate?.jobs?.length === 0}
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="person-circle-check" />
                              <Box ml={12} cursor="pointer">
                                Change Status
                              </Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem onClick={() => editCandidate(candidate)}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="pencil" />
                              <Box ml={12} cursor="pointer">
                                Edit
                              </Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleHideCandidate(candidate)}
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="eye-slash" />
                              <Box ml={12} cursor="pointer">
                                Hide
                              </Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleDeleteCandidate(candidate)}
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="trash" />
                              <Box ml={12} cursor="pointer">
                                Delete
                              </Box>
                            </Flex>
                          </MenuItem>
                        </MenuList>
                      ) : (
                        <MenuList
                          fontSize="sm"
                          sx={{
                            position: 'relative',
                            left: '38px',
                          }}
                        >
                          {HandleMenuItem}
                        </MenuList>
                      )}
                    </Menu>
                  </Flex>
                </Flex>
              </Td>
            );
          } else {
            return (
              <Td
                onClick={() => {
                  if (columns[index] != 'contact') {
                    if (
                      columns[index] == 'associated_jobs' &&
                      candidate?.jobs?.length <= 0
                    ) {
                      handleClick(candidate);
                    } else if (
                      columns[index] == 'tags' &&
                      candidate?.tags?.length <= 0
                    ) {
                      handleClick(candidate);
                    } else if (
                      columns[index] != 'associated_jobs' &&
                      columns[index] != 'tags'
                    ) {
                      handleClick(candidate);
                    }
                  }
                }}
              >
                {col}
              </Td>
            );
          }
        })}

        {/* This seems to be not being used */}
        {/* {!hasInterview && hasStatus && (
          <Td onClick={() => handleClick(candidate)}>
            <Box fontSize="14px" color="default.primarytext">
              {jobStatus(candidate)}
            </Box>
          </Td>
        )} */}
      </Tr>

      {isOpenSendEmail && (
        <EmailsModal
          isOpen={isOpenSendEmail}
          onClose={onCloseSendEmail}
          email={emails?.[0]?.email || ''}
          id={candidate.id}
          candidate_id={candidate.id}
          job_id={jobId}
          allowedCategory={['Candidates', 'Jobs', 'Signatures']}
        />
      )}

      {isOpenJobList && (
        <CandidateJobList
          jobs={jobList}
          isOpen={isOpenJobList}
          onClose={onCloseJobList}
          onSelect={(e: any) => onJobSelect(e)}
          type={actionType}
        />
      )}

      {isOpenSubmit && (
        <SubmitToClientModal
          isOpen={isOpenSubmit}
          onClose={onCloseSubmit}
          candidate={candidate}
          job_id={jobId}
          callback={async () => {
            statusChangeSuccess(candidate);
            // await reqGetCandidate({ id: candidate?.id });
            // statusChangeSuccessCb(candidate);
          }}
          jobTitle={jobTitle}
          clientName={clientName}
          jobID={jobID}
          clientID={clientID}
        />
      )}

      {isOpenChangeStatus && (
        <ChangeStatusModal
          isOpen={isOpenChangeStatus}
          onClose={onCloseChangeStatus}
          id={assocId}
          idList={assocIdList}
          main_status={mainStatus}
          sub_status={subStatus}
          onSuccess={(e: any) => statusChangeSuccess(e)}
          candidate={candidate}
          jobDetails={jobDetails}
          groupItem={groupItem}
          // isBulkChangeStatus={isBulkAction}
        />
      )}
      {isOpenEmail && (
        <EmailsModal
          isOpen={isOpenEmail}
          onClose={onCloseEmail}
          email={emails?.[0]?.email || ''}
          id={candidateId}
          candidate_id={candidateId}
          job_id={jobId ? jobId : undefined}
          allowedCategory={['Candidates', 'Jobs', 'Signatures']}
        />
      )}
    </>
  );
};

export default DynamicCandidateTR;
