import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import AtsCraetableSelDyn from 'components/app/AtsCreatabeSelect/Dyanmic';
import AtsSelect from 'components/app/AtsSelect';
import { Field, FieldProps, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  useFetchCountriesQuery,
  useFetchJobSpecificationsQuery,
} from 'services/constants/constantsApi';
import {
  useClientSearchMutation,
  useViewClientMutation,
} from 'store/client.slice';
import { addressFormat } from 'utils/utilFormat';
import { InitialValueType } from '../type';

export default function CompanyDetails() {
  const formikContext = useFormikContext<InitialValueType>();
  const { data: jobSpecificationList, isSuccess } =
    useFetchJobSpecificationsQuery({});
  const { data: countryList } = useFetchCountriesQuery({});
  const jobSpecificationOptions =
    jobSpecificationList?.map((job: any) => ({
      label: job.specification,
      value: job.id,
    })) ?? [];
  const [reqCompanyList, resCompanyList] = useClientSearchMutation(); //TODO: query based
  const [reqClientData, resClientData] = useViewClientMutation();
  const companyList =
    resCompanyList?.data?.data?.map((data: any) => ({
      value: data.id,
      label: data.name,
      website: data.website,
      address: addressFormat(data.address, countryList),
      specification: {
        label: jobSpecificationOptions.filter(
          (spec: any) => spec.value === data.spec_id
        )[0]?.label,
        value: data.spec_id,
      },
    })) ?? [];
  const [isDisabledWebsite, setIsDisabledWebsite] = useState(false);
  const [isDisabledSpecification, setIsDisabledSpecification] = useState(false);
  const [jobSpecificationValue, setJobSpecificationValue] = useState({});

  const handleSearch = debounce((search: string) => {
    reqCompanyList({ search });
  }, 400);

  useEffect(() => {
    if (isSuccess) {
      const defaultJobSpecification = jobSpecificationOptions.find(
        (job: any) => job.id === formikContext.values.specification_id
      );
      setJobSpecificationValue(defaultJobSpecification);
    }
  }, [isSuccess]);
  useEffect(() => {
    reqCompanyList({});
  }, []);
  return (
    <>
      <Text fontWeight={600} mb="34px">
        Company Details
      </Text>
      <Flex gap="32px" mb="34px">
        <Field name="title">
          {({
            form: { errors, touched, values, handleChange },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.title && touched.title)}
              isRequired
            >
              <FormLabel>Title</FormLabel>
              <Input
                id="title"
                name="title"
                type="text"
                placeholder="Title"
                variant="outline"
                value={values.title}
                onChange={handleChange}
              />
              <FormErrorMessage>{String(errors.title)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="company">
          {({
            form: { errors, touched, values, setFieldValue },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.company && touched.company)}
              isRequired
            >
              <FormLabel>Company</FormLabel>
              <AtsCraetableSelDyn
                id="company"
                name="company"
                defaultValue={values.company}
                placeholder="Company"
                onChange={(event: any) => {
                  reqClientData({ id: event?.value });
                  setFieldValue('company', event);
                  if (event?.address) {
                    setFieldValue('address', event.address);
                  }

                  if (event?.website) {
                    setFieldValue('website', event.website);
                    setIsDisabledWebsite(true);
                  }

                  if (event?.specification) {
                    setJobSpecificationValue(event.specification);
                    setFieldValue(
                      'specification_id',
                      event.specification.value
                    );
                    setIsDisabledSpecification(true);
                  }

                  if (!event) {
                    setFieldValue('address', []);
                    setFieldValue('website', '');
                    setIsDisabledWebsite(false);
                    setIsDisabledSpecification(false);
                  }
                }}
                onInputChange={handleSearch}
                isLoading={resCompanyList.isLoading}
                options={companyList}
                isClearable
              />
              <FormErrorMessage>{String(errors.company)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Flex>
      <Flex gap="32px" mb="34px">
        <Field name="specification_id">
          {({
            form: { errors, touched, values, setFieldValue },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(
                !!errors.specification_id && touched.specification_id
              )}
              isRequired
            >
              <FormLabel>Industry</FormLabel>

              <Field
                name="specification_id"
                options={jobSpecificationOptions}
                component={AtsSelect}
                value={jobSpecificationValue}
                placeholder="Select"
                onChange={(e: any) => {
                  setJobSpecificationValue(e);
                  setFieldValue('specification_id', String(e.value));
                }}
                disabled={isDisabledSpecification}
              />
              <FormErrorMessage>
                {String(errors.specification_id)}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="website">
          {({
            form: { errors, touched, values, handleChange },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.website && touched.website)}
              isRequired
            >
              <FormLabel>Company Website </FormLabel>
              <Input
                id="website"
                name="website"
                type="text"
                placeholder="Company Website"
                variant="outline"
                value={values.website}
                onChange={handleChange}
                disabled={isDisabledWebsite}
              />
              <FormErrorMessage>{String(errors.website)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Flex>
    </>
  );
}
