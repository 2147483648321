import {
  Tabs,
  TabList,
  Button as ButtonChakra,
  Tab,
  TabPanels,
  TabPanel,
  FormControl,
  Flex,
  FormLabel,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  FormErrorMessage,
  Divider,
  ModalFooter,
  Box,
  Menu,
  Text,
  Stack,
} from '@chakra-ui/react';
import Button from 'Library/Button';

import ScrollToFieldError from 'components/app/ScrollError';

import parse from 'html-react-parser';
import { Formik } from 'formik';
import AtsSelect from 'components/app/AtsSelect';
import FAIcon from 'components/lib/FAIcon';
import { useState } from 'react';
import { useAIGeneratorServices } from 'components/app/AIGenerator/useAIGeneratorServices';
import AtsEmailBody from 'components/app/Global/Email/AIBody';

interface CustomizePitchFullJobProps {
  setJobFormData: (data: any) => void;
  onSubmit: (data: any) => void;
  jobFormData: any;
  initialValues: any;
  tabList: any;
  tabIndex: any;
  setTabIndex: (data: any) => void;
  isOpenAlert: boolean;
  onOpen: () => void;
  validationSchema: any;
  jobStatus: any;
  placeholderOptions: any;
  placeHolderClick: any;
  placholders: any;
  isLoading: any;
  setErrorList: any;
  modalHeader: any;
  onClose: () => void;
}

export const CustomizePitchFullJobForm = ({
  setJobFormData,
  onSubmit,
  jobFormData,
  initialValues,
  tabList,
  tabIndex,
  setTabIndex,
  isOpenAlert,
  onOpen,
  validationSchema,
  jobStatus,
  placeholderOptions,
  placeHolderClick,
  placholders,
  isLoading,
  setErrorList,
  modalHeader,
  onClose,
}: CustomizePitchFullJobProps) => {
  const {
    hasAIGenerate,
    setHasAIGenerate,
    setIsGenerate,
    handleValues,
    handleAIGenerator,
    setIsError,
    isMaximize,
  } = useAIGeneratorServices({ hasType: 'Jobs' });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(data: any) => {
        onSubmit({
          ...jobFormData,
          status: jobStatus,
          lead_id: data.lead_id,
          email_subject: data.email_subject,
          email_content: data.email_content,
          campaign: data.campaign,
          job_status_id: 1,
        });
      }}
      enableReinitialize={true}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isValid,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} style={{ height: 'inherit' }}>
          <ScrollToFieldError />

          <Stack justifyContent="space-between" h="inherit">
            <Flex
              h="inherit"
              justifyContent="center"
              gap="10px"
              sx={{
                overflow: 'hidden',
                '& #ai': {
                  m: 0,
                  overflowY: 'scroll',
                  h: 'inherit',
                },
                '& ::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <Stack
                h="inherit"
                spacing="10px"
                sx={{
                  display: hasAIGenerate && isMaximize ? 'none' : 'flex',
                  w: hasAIGenerate !== '' ? '65%' : '100%',
                  mr: hasAIGenerate && 0,
                }}
              >
                <Stack
                  spacing="16px"
                  sx={{
                    background: '#fff',
                    borderRadius: '6px',
                    p: '16px',
                  }}
                >
                  <FormControl
                    isInvalid={Boolean(!!errors.campaign && touched.campaign)}
                  >
                    <FormLabel
                      fontSize="14px"
                      lineHeight="19.1px"
                      color="#2B2D42"
                      fontWeight={700}
                    >
                      Campaign Status
                    </FormLabel>
                    <AtsSelect
                      id="campaign"
                      name="campaign"
                      placeholder="Select"
                      variant="outline"
                      defaultValue={{
                        label: values.campaign ? 'Active' : 'Inactive',
                        value: values.campaign,
                      }}
                      onChange={(e: any) =>
                        setFieldValue('campaign', String(e.value))
                      }
                      onBlur={() =>
                        localStorage.setItem(
                          'jobFormData',
                          JSON.stringify({
                            ...jobFormData,
                            ...values,
                          })
                        )
                      }
                      options={[
                        { label: 'Active', value: true },
                        {
                          label: 'Inactive',
                          value: false,
                        },
                      ]}
                      styles={{
                        control: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          border: '1px solid #E7E9ED',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          '&:hover': {},
                        }),
                        valueContainer: (styles: any) => ({
                          ...styles,
                          maxHeight: '33px',
                          overflowY: 'scroll',
                        }),
                        dropdownIndicator: (styles: any) => ({
                          ...styles,
                          color: '#676767',
                        }),
                        indicatorSeparator: () => ({
                          display: 'none',
                        }),
                        placeholder: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          color: '#9B9B9B',
                        }),
                      }}
                    />
                    <FormErrorMessage>
                      {String(errors.campaign)}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={Boolean(
                      !!errors.email_subject && touched.email_subject
                    )}
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <FormLabel fontSize="14px" lineHeight="18px">
                        Subject
                      </FormLabel>
                    </Flex>

                    <Input
                      name="email_subject"
                      type="text"
                      placeholder="Email Subject"
                      variant="outline"
                      value={placholders(values.email_subject)}
                      onChange={(e: any) =>
                        setFieldValue('email_subject', e.target.value)
                      }
                      sx={{
                        height: '38px',
                        border: '1px solid #E7EDF4',
                        p: '8px 12px',
                        fontSize: '14px',
                      }}
                    />

                    <FormErrorMessage>
                      {String(errors.email_subject)}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>

                <Stack
                  h="45vh"
                  sx={{
                    position: 'relative',
                    bgColor: '#FFF',
                    borderRadius: '6px',
                    p: '16px',
                    overflowY: 'hidden',
                    '& ::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                >
                  <AtsEmailBody
                    value={values.email_content}
                    onChange={(e: any) => {
                      setFieldValue('email_content', e);
                    }}
                    height={220}
                    hasAIGenerate={hasAIGenerate}
                    setHasAIGenerate={setHasAIGenerate}
                    setIsGenerate={setIsGenerate}
                    handleValues={handleValues}
                    setIsError={setIsError}
                    subject={values.subject}
                    body={values.email_content}
                  />

                  <FormErrorMessage>
                    {String(errors.email_content)}
                  </FormErrorMessage>
                </Stack>
              </Stack>

              {handleAIGenerator(setFieldValue)}
            </Flex>

            <ModalFooter
              position="sticky"
              bottom="0"
              background="#F9F9FB"
              p="8px 0"
              zIndex={2}
            >
              <Flex
                gap="10px"
                justifyContent="space-between"
                sx={{ w: '100%' }}
              >
                {tabIndex.id > 0 && (
                  <Button
                    disabled={isOpenAlert}
                    mr={3}
                    onClick={() => {
                      if (tabIndex.id > 0) {
                        setTabIndex(tabList[tabIndex.id - 1]);
                        setJobFormData((prev: any) => ({
                          ...prev,
                          ...values,
                        }));
                      }
                    }}
                  >
                    Previous
                  </Button>
                )}

                <Flex gap="10px">
                  <ButtonChakra
                    onClick={() => {
                      onClose();
                      localStorage.setItem('jobFormData', JSON.stringify({}));
                    }}
                  >
                    Cancel
                  </ButtonChakra>

                  {((String(jobFormData.job_type_id) === '1' &&
                    tabIndex.id === 4) ||
                    (String(jobFormData.job_type_id) === '2' &&
                      tabIndex.id === 2)) && (
                    <Button
                      type="submit"
                      variant="solid"
                      loading={isLoading}
                      onClick={(e: any) => {
                        handleSubmit(e);
                        setErrorList(errors);
                        !isValid && onOpen();
                      }}
                      disabled={isOpenAlert || isLoading}
                    >
                      {modalHeader === 'Edit Job' ? 'Update' : 'Save'}
                    </Button>
                  )}
                </Flex>
              </Flex>
            </ModalFooter>
          </Stack>
        </form>
      )}
    </Formik>
  );
};
