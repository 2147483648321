import { Box, Flex, MenuItem } from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import React from 'react';

export function RequestToConvert(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem {...props}>
      <Flex alignItems="center" justifyContent="space-between">
        <FAIcon iconName="briefcase" />
        <Box ml={12}>Request to Convert</Box>
      </Flex>
    </MenuItem>
  );
}
export function MarkAsInactive(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem {...props} color="caution.800">
      <Flex alignItems="center" justifyContent="space-between">
        <FAIcon iconName="ban" />
        <Box ml={12}>Mark as Inactive</Box>
      </Flex>
    </MenuItem>
  );
}
export function CancelApproval(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem {...props}>
      <Flex alignItems="center" justifyContent="space-between">
        <FAIcon iconName="times" />
        <Box ml={12}>Cancel Approval</Box>
      </Flex>
    </MenuItem>
  );
}
export function ViewContact(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem {...props}>
      <Flex alignItems="center" justifyContent="space-between">
        <FAIcon iconName="briefcase" />
        <Box ml={12}>View Contact</Box>
      </Flex>
    </MenuItem>
  );
}
export function SendForApproval(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem {...props}>
      <Flex alignItems="center" justifyContent="space-between">
        <FAIcon iconName="check-circle" />
        <Box ml={12}>Send for Approval</Box>
      </Flex>
    </MenuItem>
  );
}
export function MarkAsActive(props: React.ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem {...props}>
      <Flex alignItems="center" justifyContent="space-between">
        <FAIcon iconName="square-check" />
        <Box ml={12}>Mark as Active</Box>
      </Flex>
    </MenuItem>
  );
}
