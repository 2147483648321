import { Flex, Image, Stack, Text } from '@chakra-ui/react';
import highlight from 'assets/images/inboxes/MdHighlight.svg';
import DetailsActive from 'assets/images/leads/details-active.svg';
import DetailsDefault from 'assets/images/leads/details-default.svg';
import NewProspectActive from 'assets/images/leads/new-prospects-active.svg';
import NewProspectDefault from 'assets/images/leads/new-prospects.svg.svg';
import ExistingClientProspectActive from 'assets/images/leads/existing-client-prospect-active.svg';
import ExistingClientProspectDefault from 'assets/images/leads/existing-client-prospect.svg.svg';
import UserActive from 'assets/images/leads/user-active.svg';
import UserDefault from 'assets/images/leads/user-default.svg';
import AtsSelect from 'components/app/AtsSelect';
import { CheckboxLayout } from 'components/app/Global/Checkbox';
import { SearchBar } from 'components/app/Global/SearchBar';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import {
  setDateFilter,
  setQuery as setFilterQuery,
  setStatus,
} from 'store/leads.slice';
import {
  MY_LEADS,
  PROSPECTS,
  UNCLAIMED,
} from '../../constants/filter-constants';
import { useFetchLeadsCountsQuery } from '../../service/leadsApi';

const MAIN_MENU: {
  id: number;
  label: string;
  image: string;
  imageActive: string;
  countId:
    | 'myleads'
    | 'unclaimed'
    | 'new_prospects'
    | 'existing_client_prospects';
  link: string;
  value: string;
}[] = [
  {
    id: 0,
    label: 'My Leads',
    image: UserDefault,
    imageActive: UserActive,
    value: 'my-leads',
    link: '/leads/my-leads',
    countId: 'myleads',
  },
  {
    id: 1,
    label: 'Unclaimed Leads',
    image: DetailsDefault,
    imageActive: DetailsActive,
    value: 'unclaimed',
    link: '/leads/unclaimed-leads',
    countId: 'unclaimed',
  },
  {
    id: 2,
    label: 'New Prospects',
    image: NewProspectDefault,
    imageActive: NewProspectActive,
    value: 'new_prospects',
    link: '/leads/new-prospects',
    countId: 'new_prospects',
  },
  {
    id: 3,
    label: 'Prospects w/ Existing Clients',
    image: ExistingClientProspectDefault,
    imageActive: ExistingClientProspectActive,
    value: 'existing_client_prospects',
    link: '/leads/existing-client-prospects',
    countId: 'existing_client_prospects',
  },
];

type DateOptionType = { label: string; value: string };
const DATE_OPTIONS: Array<DateOptionType> = [
  { label: 'All', value: 'null' },
  { label: 'Last Week', value: 'Last Week' },
  { label: 'Last Month', value: 'Last Month' },
  { label: 'Last 3 Months', value: 'Last 3 Months' },
  { label: 'Last 6 Months', value: 'Last  6 Months' },
];
const LeadSideBar = () => {
  const dispatch = useDispatch();
  const { data } = useFetchLeadsCountsQuery({});
  const { pathname } = useLocation();
  const { dateFilter } = useSelector((state: any) => state.leads);
  const [query, setQuery] = useState('');

  const {
    isProspectRoute,
    isUnclaimedLeadsRoute,
    isMyLeadsRoute,
    isNewProspects,
  } = useMemo(() => {
    return {
      isProspectRoute: pathname.includes('prospects'),
      isUnclaimedLeadsRoute: pathname.includes('/unclaimed-leads'),
      isMyLeadsRoute: pathname.includes('/my-leads'),
      isNewProspects: pathname.includes('/new-prospects'),
    };
  }, [pathname]);

  const handleSearchChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      if (query === '') {
        value = '';
      }
      if (query.length >= 3 || value.length >= 3) {
        dispatch(setFilterQuery({ query: value }));
      }
    },
    300
  );

  function handleStatusChange(filter: number[][]) {
    const status = [...new Set(filter.flat())];
    dispatch(setStatus({ status }));
  }

  return (
    <Stack
      spacing="9px"
      sx={{
        borderRadius: '10px',
        width: '320px',
        bgColor: '#F9F9FB',
        fontFamily: 'NunitoSans Regular',
        overflowY: 'auto',
      }}
    >
      <Stack
        spacing="16px"
        sx={{
          bgColor: '#fff',
          borderRadius: '10px',
        }}
      >
        <Stack spacing="10px" sx={{ position: 'relative', p: '16px' }}>
          {MAIN_MENU.map((val) => {
            return (
              <NavLink to={val.link}>
                {({ isActive }) => (
                  <Flex
                    gap="10px"
                    sx={{
                      alignItems: 'center',
                      p: '4px 8px',
                      cursor: 'pointer',
                      borderRadius: '4px',
                      background: isActive && '#6930CA',
                      color: isActive ? '#FFFFFF' : '#2B2D42',
                    }}
                  >
                    {isActive && (
                      <Image
                        src={highlight}
                        height="35px"
                        width="4px"
                        sx={{ position: 'absolute', left: 0 }}
                        alt="highlight"
                      />
                    )}
                    <Image
                      src={isActive ? val.imageActive : val.image}
                      height="24px"
                      width="24px"
                      alt={`${val.label}--${isActive ? 'active' : 'inactive'}`}
                    />
                    <Flex alignItems="center" columnGap="8px">
                      <Text sx={{ fontSize: '14px', fontWeight: 600 }}>
                        {val.label}
                      </Text>
                      <Flex
                        bgColor={isActive ? '#ffff' : '#6930CA14'}
                        color="#6930CA"
                        borderRadius={'2px'}
                        minW={'23px'}
                        h={'18px'}
                        justifyContent="center"
                        alignItems="center"
                        p={'4px'}
                      >
                        <Text fontSize={'12px'} fontWeight={600}>
                          {data?.[`${val.countId}`]}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </NavLink>
            );
          })}
        </Stack>
      </Stack>

      <Stack
        direction={'column'}
        gap="16px"
        sx={{
          bgColor: '#fff',
          borderRadius: '10px',
          p: '16px',
        }}
        height="100%"
      >
        <Stack direction="column" gap="16px">
          <Flex
            sx={{
              borderBottom: '1px solid #E7EDF4',
              pb: '8px',
              color: '#718096',
              fontSize: '16px',
              fontWeight: '600',
              // lineHeight: '21.82px',
            }}
          >
            Filters
          </Flex>

          <SearchBar
            search={query}
            setSearch={setQuery}
            placeholder={
              isProspectRoute ? 'Search prospects...' : 'Search leads...'
            }
            handleOnChange={handleSearchChange}
          />
        </Stack>

        <Stack spacing="8px">
          {isMyLeadsRoute && (
            <>
              <Text
                color="#718096"
                fontSize="14px"
                fontWeight="600"
                lineHeight="19.1px"
              >
                Status
              </Text>

              <CheckboxLayout
                data={MY_LEADS}
                setStatusPayload={handleStatusChange}
              />
            </>
          )}

          {isUnclaimedLeadsRoute && (
            <>
              <Text
                color="#718096"
                fontSize="14px"
                fontWeight="600"
                lineHeight="19.1px"
              >
                Status
              </Text>
              <CheckboxLayout
                data={UNCLAIMED}
                setStatusPayload={handleStatusChange}
              />
            </>
          )}

          {isProspectRoute && (
            <>
              <Text
                color="#718096"
                fontSize="14px"
                fontWeight="600"
                lineHeight="19.1px"
              >
                Prospect Status
              </Text>
              <CheckboxLayout
                data={PROSPECTS}
                setStatusPayload={handleStatusChange}
                counts={
                  isNewProspects
                    ? data?.prospect_status_count
                    : data?.prospect_repeat_status_count
                }
              />
            </>
          )}
        </Stack>

        {isProspectRoute && (
          <Stack spacing="8px">
            <Text
              color="#718096"
              fontSize="14px"
              fontWeight="600"
              lineHeight="19.1px"
            >
              Created Date
            </Text>

            <AtsSelect
              placeholder="Select"
              defaultValue={`${dateFilter}`}
              options={DATE_OPTIONS}
              onChange={(e: DateOptionType) =>
                dispatch(
                  setDateFilter({
                    dateFilter: e.value === 'null' ? null : e.value,
                  })
                )
              }
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
export default LeadSideBar;
