import moment from 'moment';

import {
  Box,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import Pagination from 'Library/Pagination';

import LeadStatus from 'components/app/LeadStatus';
import FAIcon from 'components/lib/FAIcon';

import RequestToConvertModal from 'modules/Leads/components/request-to-convert-modal';
import SendForApprovalModal from 'modules/Leads/components/send-for-approval-modal';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { LeadsAPIResponseType } from '../../types';
import AddLeadModal from '../add-edit-lead-modal/AddLeadModal';
import BulkAction from './components/BulkAction';
import {
  CancelApproval,
  MarkAsActive,
  MarkAsInactive,
  RequestToConvert,
  SendForApproval,
  ViewContact,
} from './components/MenuItems';
import SkeletonLoader from './components/SkeletonLoader';
import { TableProps } from './TableInterface';

export default function LeadsTable({
  rows,
  onDateSort,
  onSubmitEmail,
  onAddLead,
  onBulkInActive,
  onCancelApproval,
  onChangeToActiveStatus,
  onChangeToInActiveStatus,
  onConvertToClient,
  onSendApproval,
  onOpenDetailDrawer,
  isLoading,
  pagination: {
    totalPages,
    currentPage,
    onPageChange,
    totalEntries,
    onEntryChange,
    currentCount,
    targetCount,
  },
}: TableProps) {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const leadIdList = useMemo(() => rows.map((lead: any) => lead.id), [rows]);
  const tableHeader: { label: any }[] = useMemo(() => {
    function handleSelectAllRows(e: React.ChangeEvent<HTMLInputElement>) {
      const { checked } = e.target;

      if (checked) {
        setSelectedRows(leadIdList);
      } else {
        setSelectedRows([]);
      }
    }
    return [
      {
        label: (
          <Checkbox
            onChange={handleSelectAllRows}
            isIndeterminate={
              selectedRows.length && rows.length !== selectedRows.length
            }
            isChecked={
              selectedRows.length && rows.length === selectedRows.length
            }
            defaultChecked={false}
            colorScheme="purple"
          />
        ),
      },
      { label: 'Lead Details' },
      { label: 'Contact Information' },
      { label: 'Status' },
      {
        label: (
          <Box>
            <FAIcon iconName="location-pin" /> Open Jobs
          </Box>
        ),
      },
      {
        label: (
          <Box>
            <FAIcon iconName="clipboard" /> Submits
          </Box>
        ),
      },
      {
        label: (
          <Box>
            <FAIcon iconName="phone" /> Interviews
          </Box>
        ),
      },
      {
        label: (
          <Flex gap="10px" justifyContent="start" alignItems="center">
            Created On
            <Flex onClick={onDateSort} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        ),
      },
      { label: '' },
    ];
  }, [onDateSort, rows.length, leadIdList, selectedRows]);

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const [sendToApproval, setSendToApproval] = useState({
    id: null,
    isOpen: false,
  });
  const [convertToClient, setConvertToClient] = useState({
    id: null,
    isOpen: false,
  });

  const renderMenuList = useCallback(
    (lead: LeadsAPIResponseType) => {
      const type = lead?.leadType?.type;
      const status = lead?.leadStatus?.lead_status;
      let menus: React.ReactNode[] = [];

      if (
        status !== 'Approval Pending' &&
        status !== 'Inactive' &&
        type !== 'Cold' &&
        type !== 'Contact'
      ) {
        menus = menus.concat([
          <RequestToConvert
            onClick={() => setConvertToClient({ isOpen: true, id: lead.id })}
          />,
        ]);
      }
      if (status === 'Approval Pending') {
        menus = menus.concat([
          <CancelApproval onClick={() => onCancelApproval(lead)} />,
        ]);
      }
      if (status === 'Inactive') {
        menus = menus.concat(
          <MarkAsActive onClick={() => onChangeToActiveStatus(lead)} />
        );
      }
      if (status === 'Active') {
        menus = menus.concat(<ViewContact />);
      }
      if (
        (type === 'Qualified' || type === 'Lead') &&
        (status === 'Draft' || status === 'Rejected')
      ) {
        menus = menus.concat(
          <SendForApproval
            onClick={() => {
              setSendToApproval({ isOpen: true, id: lead.id });
            }}
          />
        );
      }
      if (status === 'Active' || status === 'Draft') {
        menus = menus.concat(
          <MarkAsInactive
            onClick={() => {
              onChangeToInActiveStatus(lead);
            }}
          />
        );
      }

      return menus;
    },
    [
      onCancelApproval,
      onChangeToActiveStatus,
      onConvertToClient,
      onChangeToInActiveStatus,
    ]
  );

  function handleRowSelection(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.target;
    const leadId = parseInt(name);
    setSelectedRows((state) => [...state, leadId]);
    if (!checked) {
      setSelectedRows((state) => state.filter((id) => id !== leadId));
    }
  }

  function handleClick(lead: LeadsAPIResponseType, index: number) {
    navigate(`/leads/my-leads/${lead.id}/details`);
    onOpenDetailDrawer(index);
  }
  if (isLoading) return <SkeletonLoader />;
  return (
    <>
      <Flex justifyContent="space-between" pb={'16px'}>
        <Flex gap={4}>
          {selectedRows.length > 0 ? (
            <BulkAction
              totalSelected={selectedRows.length}
              onMarkInActive={() => onBulkInActive(selectedRows)}
            />
          ) : (
            <Box mb={6}></Box>
          )}
        </Flex>

        <Button leftIcon="plus" variant="solid" onClick={onOpenModal}>
          Add New lead
        </Button>
      </Flex>

      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="inherit"
        sx={{ overflowY: 'auto' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              {tableHeader.map((title, key: number) => (
                <Th
                  key={`lead-th-${key}`}
                  sx={{ position: 'sticky', top: 0, zIndex: 10 }}
                  bg="default.white.600"
                >
                  <Box color="default.gray.600">{title.label}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {rows.map((lead, index) => (
              <Tr
                key={`lead-tr-${lead?.id}`}
                _hover={{
                  bg: '#f8f9fa',
                }}
                cursor="pointer"
              >
                <Td w={50}>
                  <Checkbox
                    onChange={handleRowSelection}
                    isChecked={selectedRows.includes(lead?.id)}
                    name={lead?.id?.toString()}
                    key={lead?.id}
                    colorScheme="purple"
                  />
                </Td>
                <Td onClick={() => handleClick(lead, index)} width={250}>
                  <Box whiteSpace="pre-line" maxWidth="250px">
                    <Box
                      fontWeight="bold"
                      textTransform="capitalize"
                      style={{ textWrap: 'nowrap' }}
                    >
                      {[lead?.first_name, lead?.last_name].join(' ')}
                    </Box>
                    <Box fontSize="sm"> {lead?.title}</Box>
                    <Box fontSize="sm"> {lead?.client?.name}</Box>
                  </Box>
                </Td>

                <Td onClick={() => handleClick(lead, index)}>
                  <Box>
                    <Box fontWeight="bold" fontSize="sm">
                      {lead?.primary_email}
                    </Box>
                    <Box fontSize="sm"> {lead?.personal_phone}</Box>
                  </Box>
                </Td>
                <Td onClick={() => handleClick(lead, index)}>
                  <LeadStatus status={lead?.leadStatus?.lead_status} />
                </Td>
                <Td width="50px" p="5px 24px" fontSize="14px">
                  {lead?.total_opening}
                </Td>
                <Td width="50px" p="5px 24px" fontSize="14px">
                  {lead?.total_submits}
                </Td>
                <Td width="50px" p="5px 24px" fontSize="14px">
                  {lead?.total_interview}
                </Td>

                <Td width="50px" p="5px 24px" fontSize="14px">
                  {moment.utc(lead?.created_at).format('MM/DD/YYYY hh:mm A')}
                </Td>

                <Td>
                  <Menu>
                    <MenuButton>
                      <Button
                        htmlType="menu"
                        variant="ghost"
                        iconName="ellipsis-h"
                        iconSize="xl"
                      />
                    </MenuButton>

                    <MenuList fontSize="sm">{renderMenuList(lead)}</MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalEntries={totalEntries}
        onEntryChange={onEntryChange}
        currentCount={currentCount}
        targetCount={targetCount}
      />
      {sendToApproval.isOpen ? (
        <SendForApprovalModal
          isOpen={sendToApproval.isOpen}
          leadId={sendToApproval.id}
          onClose={() => setSendToApproval({ isOpen: false, id: null })}
          onSubmit={onSendApproval}
        />
      ) : null}

      {convertToClient.isOpen ? (
        <RequestToConvertModal
          isOpen={convertToClient.isOpen}
          leadId={convertToClient.id}
          onClose={() => setConvertToClient({ isOpen: false, id: null })}
          onSubmit={onConvertToClient}
        />
      ) : null}

      {isOpenModal ? (
        <AddLeadModal
          isOpen={isOpenModal}
          onClose={onCloseModal}
          onSubmit={onAddLead}
        />
      ) : null}
    </>
  );
}
