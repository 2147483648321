import { apiSlice } from 'store/api.slice';
import {
  ClientLeadList,
  ClientLeadListResponse,
  ExistingClientProspectResType,
  LeadCountsAPIResponseType,
  LeadCountWithAllType,
  LeadsApiPayload,
  LeadsDetailAPIResponseType,
  LeadsDetailType,
  LeadsListResponsetype,
} from '../types';

export const leadsAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchLeadsCounts: builder.query<LeadCountWithAllType, any>({
      query: () => ({
        url: '/leads/count',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: ['Leads-Count'],
      transformResponse: (response: { data: LeadCountsAPIResponseType }) => {
        const totalNewProspects = Object.values(
          response.data.prospect_status_count
        ).reduce((acc, cur) => acc + cur);
        const totalExistingClientProspects = Object.values(
          response.data.prospect_repeat_status_count
        ).reduce((acc, cur) => acc + cur);

        let data = {
          ...response.data,
          new_prospects: totalNewProspects,
          existing_client_prospects: totalExistingClientProspects,
          prospect_status_count: {
            All: totalNewProspects,
            ...response.data.prospect_status_count,
          },
          prospect_repeat_status_count: {
            All: totalExistingClientProspects,
            ...response.data.prospect_repeat_status_count,
          },
        };

        return data;
      },
    }),
    fetchLeads: builder.query<LeadsListResponsetype, LeadsApiPayload>({
      query: (payload, signal?: AbortSignal) => ({
        url: '/leads/',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: payload,
        signal,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                (data) =>
                  ({
                    type: 'Leads',
                    id: data.id,
                  } as const)
              ),
              { type: 'Leads', id: 'Leads List' },
            ]
          : [{ type: 'Leads', id: 'Leads List' }],
      transformResponse: (result: { data: LeadsListResponsetype }) => {
        return result.data;
      },
    }),
    fetchExistingClientProspects: builder.query<
      ExistingClientProspectResType,
      LeadsApiPayload
    >({
      query: (payload, signal?: AbortSignal) => ({
        url: '/leads',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: payload,
        signal,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                (data) =>
                  ({
                    type: 'Existing-Client-Prospects',
                    id: data?.client_id,
                  } as const)
              ),
              { type: 'Existing-Client-Prospects', id: 'New Prospect list' },
            ]
          : [{ type: 'Existing-Client-Prospects', id: 'New Prospect list' }],
      transformResponse: (result: { data: ExistingClientProspectResType }) => {
        return result.data;
      },
    }),
    fetchLeadDetail: builder.query<LeadsDetailType, { id: number }>({
      query: ({ id }) => ({
        url: `/leads/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: (result: any, error: any, { id }: { id: number }) => [
        { type: 'Leads-Details', id },
      ],
      transformResponse: (result: LeadsDetailAPIResponseType) => {
        return result.data;
      },
    }),
    fetchLeadStatus: builder.query({
      query: () => ({
        url: `/constants/lead-status`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: ['Lead-Status'],
      transformResponse: (response: { data: any }) => {
        return response.data;
      },
    }),
    fetchLeadTypes: builder.query({
      query: () => ({
        url: `/constants/lead-types/filtered`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: ['Lead-Types'],
      transformResponse: (response: { data: any }) => {
        return response.data;
      },
    }),
    fetchClient: builder.query<ClientLeadList[], { id: number }>({
      query: ({ id }) => ({
        url: `/leads/client/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: (result: any, error: any, { id }: { id: number }) => [
        { type: 'Leads-Client', id },
      ],
      transformResponse: (result: ClientLeadListResponse) => {
        return result.data;
      },
    }),
    fetchAsContact: builder.query({
      query: ({ id }) => ({
        url: `/leads/as-contact/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: (result: any, error: any, { id }: { id: number }) => [
        { type: 'Leads-as-Contact', id },
      ],
    }),

    addLead: builder.mutation({
      query: (data) => ({
        url: '/leads/create',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result, error, arg) =>
        result && !error ? [{ type: 'Leads', id: 'Leads List' }] : [],
    }),
    addLeadContact: builder.mutation({
      query: (data) => ({
        url: '/leads/create/contact',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
    }),
    updateLead: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
    setLeadInactive: builder.mutation({
      query: ({ id, inactive_reason }) => ({
        url: `/leads/inactive/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: { inactive_reason },
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
    setLeadQualified: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/qualified/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
    setLeadToClient: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/convert-to-client/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
    sendForApproval: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/send-for-approval/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
    setLeadActive: builder.mutation({
      query: ({ id }) => ({
        url: `/leads/re-activate/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
    setCancelApproval: builder.mutation({
      query: ({ id }) => ({
        url: `/leads/cancel-approval/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
    setBulkLeadsInactive: builder.mutation({
      query: ({ data }) => ({
        url: `/leads/bulk-inactive`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error) =>
        result && !error ? [{ type: 'Leads', id: 'Leads List' }] : [],
    }),
    updateContactLead: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/update-contact/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
    }),
    setLeadClaim: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/claim/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
  }),
});

export const {
  useFetchLeadsCountsQuery,
  useFetchExistingClientProspectsQuery,

  useFetchLeadsQuery,
  useLazyFetchLeadsQuery,

  useFetchLeadDetailQuery,
  useFetchAsContactQuery,
  useFetchClientQuery,
  useFetchLeadStatusQuery,
  useFetchLeadTypesQuery,
  useAddLeadContactMutation,
  useUpdateLeadMutation,
  useSendForApprovalMutation,
  useAddLeadMutation,
  useSetBulkLeadsInactiveMutation,
  useSetCancelApprovalMutation,
  useSetLeadActiveMutation,
  useSetLeadClaimMutation,
  useUpdateContactLeadMutation,
  useSetLeadToClientMutation,
  useSetLeadQualifiedMutation,
  useSetLeadInactiveMutation,
} = leadsAPI;
