import {
  Box,
  Button as ButtonChakra,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalFooter,
  Stack,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import AtsSelect from 'components/app/AtsSelect';
import ScrollToFieldError from 'components/app/ScrollError';
import FAIcon from 'components/lib/FAIcon';
import { Formik, FieldArray, Field } from 'formik';

import Button from 'Library/Button';
import { useState } from 'react';
import { BsPercent } from 'react-icons/bs';
import { useRecruitingTeamServices } from './useRecruitingTeamServices';

interface RecruitingProps {
  setJobFormData: (data: any) => void;
  initialRecruitersValues: any;
  setJobStatus: (data: any) => void;
  onSubmit: (data: any) => void;
  jobFormData: any;
  initialValues: any;
  tabList: any;
  tabIndex: any;
  setTabIndex: (data: any) => void;
  aMOption: Array<object>;
  recOptions: Array<object>;
  isOpenAlert: boolean;
  loading: boolean;
  onOpen: () => void;
  onClose: () => void;
  isDraft: boolean;
  setIsDraft: (e: boolean) => void;
  isEditModal: boolean;
}

const RecruitingTeam = ({
  setJobFormData,
  initialRecruitersValues,
  setJobStatus,
  onSubmit,
  jobFormData,
  initialValues,
  tabList,
  tabIndex,
  setTabIndex,
  aMOption,
  recOptions,
  isOpenAlert,
  loading,
  onOpen,
  onClose,
  isDraft,
  setIsDraft,
  isEditModal,
}: RecruitingProps) => {
  const { validationRecruitersSchema, recruiterSubmit, setRecruiter } =
    useRecruitingTeamServices({
      initialValues,
      setJobStatus,
      setJobFormData,
      onSubmit,
      jobFormData,
      tabList,
      tabIndex,
      setTabIndex,
      isDraft,
    });

  const reviewFields = [
    'no_submittal_review',
    'review_send_as_client_owner',
    'review_send_as_client_recruiter',
  ];

  console.log("aMOption-d", aMOption);
  const getSelectedReview = () => {
    const recruiter = initialValues?.recruiters[0];

    if (initialValues?.recruiters.length > 0) {
      for (const field of reviewFields) {
        if (recruiter[field] === true) {
          return field;
        }
      }
    }
    return '';
  };
  const [selectedReview, setSelectedReview] = useState(getSelectedReview());

  const handleReview = (value: string) => {
    setSelectedReview(value);
  };

  const updateReview = (selectedField: any, data: any) => {
    const recruiter = data.recruiters[0];

    if (data.recruiters.length > 0) {
      reviewFields.forEach((field) => {
        recruiter[field] = false;
      });

      if (
        reviewFields.includes(selectedField) &&
        recruiter.hasOwnProperty(selectedField)
      ) {
        recruiter[selectedField] = true;
      }
    } else {
      return data;
    }
  };

  const interviewFields = [
    'interview_send_as_client_owner',
    'interview_send_as_recruiter',
  ];

  const getSelectedInterview = () => {
    const recruiter = initialValues?.recruiters[0];

    if (initialValues?.recruiters.length > 0) {
      for (const field of interviewFields) {
        if (recruiter[field] === true) {
          return field;
        }
      }
    }

    return '';
  };
  const [selectedInterview, setSelectedInterview] = useState(
    getSelectedInterview()
  );

  const handleInterview = (value: string) => {
    setSelectedInterview(value);
  };

  const updateInterview = (selectedField: any, data: any) => {
    const recruiter = data.recruiters[0];

    if (data.recruiters.length > 0) {
      interviewFields.forEach((field) => {
        recruiter[field] = false;
      });

      if (
        interviewFields.includes(selectedField) &&
        recruiter.hasOwnProperty(selectedField)
      ) {
        recruiter[selectedField] = true;
      }
    } else {
      return data;
    }
  };

  return (
    <Formik
      initialValues={initialRecruitersValues}
      validationSchema={validationRecruitersSchema}
      onSubmit={(data, { setSubmitting, validateForm }) => {
        updateReview(selectedReview, data);
        updateInterview(selectedInterview, data);
        recruiterSubmit(data, { setSubmitting, validateForm });
      }}
      enableReinitialize={true}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isValid,
        setFieldValue: recruitingField,
      }) => (
        <form onSubmit={handleSubmit} style={{ height: 'inherit' }}>
          <ScrollToFieldError />
          {/* Recruiting Team */}

          <Stack justifyContent="space-between" h="inherit">
            <Stack spacing="10px">
              <Box p="16px" background="#fff" borderRadius="8px">
                <Flex gap="16px" mb="24px">
                  <FormControl
                    isInvalid={Boolean(!!errors.am_id && touched.am_id)}
                    mb="16px"
                  >
                    <FormLabel
                      fontSize="14px"
                      lineHeight="19.1px"
                      color="#2B2D42"
                      fontWeight={700}
                    >
                      Account Manager{' '}
                      <Box as="span" color="#C53030">
                        *
                      </Box>
                    </FormLabel>
                    <AtsSelect
                      name="am_id"
                      id="am_id"
                      placeholder="Select"
                      variant="outline"
                      onChange={(e: any) => recruitingField('am_id', e.value)}
                      onBlur={() =>
                        localStorage.setItem(
                          'jobFormData',
                          JSON.stringify({
                            ...jobFormData,
                            ...values,
                          })
                        )
                      }
                      value={
                        (aMOption?.length > 0 &&
                          aMOption
                            ?.map((item: any) => ({
                              label: item.label,
                              value: item.value,
                            }))
                            ?.filter(
                              (val: any) => Number(val.value) === Number(values.am_id)
                            )[0]) ||
                        values.am_id
                      }
                      options={
                        aMOption?.length > 0 &&
                        aMOption?.map((item: any) => ({
                          label: item.label,
                          value: item.value,
                        }))
                      }
                      styles={{
                        control: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          border: '1px solid #E7E9ED',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          '&:hover': {},
                          '& ::-webkit-scrollbar': {
                            display: 'none',
                          },
                        }),
                        valueContainer: (styles: any) => ({
                          ...styles,
                          maxHeight: '33px',
                          overflowY: 'scroll',
                        }),
                        dropdownIndicator: (styles: any) => ({
                          ...styles,
                          color: '#676767',
                        }),
                        indicatorSeparator: () => ({
                          display: 'none',
                        }),
                        placeholder: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          color: '#9B9B9B',
                        }),
                      }}
                    />
                    <FormErrorMessage>{String(errors.am_id)}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={Boolean(
                      !!errors.primary_recruiter && touched.primary_recruiter
                    )}
                  >
                    <FormLabel
                      fontSize="14px"
                      lineHeight="19.1px"
                      color="#2B2D42"
                      fontWeight={700}
                    >
                      Primary Recruiter{' '}
                      <Box as="span" color="#C53030">
                        *
                      </Box>
                    </FormLabel>

                    <AtsSelect
                      name="primary_recruiter"
                      id="primary_recruiter"
                      placeholder="Select"
                      variant="outline"
                      onChange={(e: any) => {
                        recruitingField('primary_recruiter', e.value);
                      }}
                      onBlur={() =>
                        localStorage.setItem(
                          'jobFormData',
                          JSON.stringify({
                            ...jobFormData,
                            ...values,
                          })
                        )
                      }
                      value={
                        (recOptions?.length > 0 &&
                          recOptions
                            .map((item: any) => ({
                              label: item.label,
                              value: item.value,
                            }))
                            .filter(
                              (val: any) =>
                                val.value === Number(values.primary_recruiter)
                            )[0]) ||
                        values.primary_recruiter
                      }
                      options={
                        recOptions?.length > 0 &&
                        recOptions.map((item: any) => ({
                          label: item.label,
                          value: item.value,
                        }))
                      }
                      styles={{
                        control: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          border: '1px solid #E7E9ED',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          '&:hover': {},
                          '& ::-webkit-scrollbar': {
                            display: 'none',
                          },
                        }),
                        valueContainer: (styles: any) => ({
                          ...styles,
                          maxHeight: '33px',
                          overflowY: 'scroll',
                        }),
                        dropdownIndicator: (styles: any) => ({
                          ...styles,
                          color: '#676767',
                        }),
                        indicatorSeparator: () => ({
                          display: 'none',
                        }),
                        placeholder: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          color: '#9B9B9B',
                        }),
                      }}
                    />
                    <FormErrorMessage>
                      {String(errors.primary_recruiter)}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
              </Box>

              <Box
                h="inherit"
                p={values.recruiters?.length === 0 && '16px'}
                background={values.recruiters?.length === 0 && '#fff'}
                borderRadius="8px"
              >
                <FieldArray name="recruiters">
                  {({ remove, push }) => (
                    <Stack spacing="8px">
                      {values.recruiters?.length > 0 &&
                        values.recruiters.map(
                          (recruiter: any, index: number) => {
                            const recruiter_name = `recruiters[${index}].name`;
                            const split_name = `recruiters[${index}].split_percentage`;
                            const post_new_job_ads = `recruiters[${index}]post_new_job_ads`;
                            const no_submittal_review = `recruiters[${index}]no_submittal_review`;
                            const review_send_as_client_owner = `recruiters[${index}]review_send_as_client_owner`;
                            const review_send_as_client_recruiter = `recruiters[${index}]review_send_as_client_recruiter`;
                            const interview_send_as_client_owner = `recruiters[${index}]interview_send_as_client_owner`;
                            const interview_send_as_recruiter = `recruiters[${index}]interview_send_as_recruiter`;

                            return (
                              <Box
                                background="#fff"
                                borderRadius="8px"
                                p="16px"
                                pr="28px"
                              >
                                <Box className="row" key={`recr-${index}`}>
                                  {values.recruiters?.length > 0 && (
                                    <Box mb="10px" textAlign="right">
                                      <Button
                                        variant="delete"
                                        leftIcon="close"
                                        onClick={() => remove(index)}
                                        size="sm"
                                      >
                                        Remove
                                      </Button>
                                    </Box>
                                  )}
                                  <Flex gap="8px" mb="24px">
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!(errors as any)?.recruiters?.[index]
                                          ?.name &&
                                          (touched as any)?.recruiters?.[index]
                                            ?.name
                                      )}
                                    >
                                      <Flex
                                        justifyContent="space-between"
                                        alignItems="flex-end"
                                      >
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="19.1px"
                                          color="#2B2D42"
                                          fontWeight={700}
                                        >
                                          Recruiter{' '}
                                          <Box as="span" color="#C53030">
                                            *
                                          </Box>
                                        </FormLabel>
                                      </Flex>

                                      <Box
                                        border={
                                          !!(errors as any)?.recruiters?.[index]
                                            ?.name &&
                                          (touched as any)?.recruiters?.[index]
                                            ?.name &&
                                          '1px solid #E53E3E'
                                        }
                                        borderRadius={
                                          !!(errors as any)?.recruiters?.[index]
                                            ?.name &&
                                          (touched as any)?.recruiters?.[index]
                                            ?.name &&
                                          '4px'
                                        }
                                        boxShadow={
                                          !!(errors as any)?.recruiters?.[index]
                                            ?.name &&
                                          (touched as any)?.recruiters?.[index]
                                            ?.name &&
                                          '0 0 0 1px #E53E3E'
                                        }
                                      >
                                        <AtsSelect
                                          name={recruiter_name}
                                          id={recruiter_name}
                                          placeholder="Select Recruiter"
                                          variant="outline"
                                          onChange={(e: any) =>
                                            recruitingField(
                                              recruiter_name,
                                              String(e.value)
                                            )
                                          }
                                          onBlur={() =>
                                            localStorage.setItem(
                                              'jobFormData',
                                              JSON.stringify({
                                                ...jobFormData,
                                                ...values,
                                              })
                                            )
                                          }
                                          defaultValue={
                                            (recOptions?.length > 0 &&
                                              recOptions
                                                .map((item: any) => ({
                                                  label: item.label,
                                                  value: item.value,
                                                }))
                                                .filter(
                                                  (val: any) =>
                                                    val.value ===
                                                    Number(recruiter.name)
                                                )[0]) ||
                                            recruiter.name
                                          }
                                          options={
                                            recOptions?.length > 0 &&
                                            recOptions.map((item: any) => ({
                                              label: item.label,
                                              value: item.value,
                                            }))
                                          }
                                          styles={{
                                            control: (styles: any) => ({
                                              ...styles,
                                              fontSize: '14px',
                                              border: '1px solid #E7E9ED',
                                              borderRadius: '4px',
                                              cursor: 'pointer',
                                              boxShadow: 'none',
                                              '&:hover': {},
                                              '& ::-webkit-scrollbar': {
                                                display: 'none',
                                              },
                                            }),
                                            valueContainer: (styles: any) => ({
                                              ...styles,
                                              maxHeight: '33px',
                                              overflowY: 'scroll',
                                            }),
                                            dropdownIndicator: (
                                              styles: any
                                            ) => ({
                                              ...styles,
                                              color: '#676767',
                                            }),
                                            indicatorSeparator: () => ({
                                              display: 'none',
                                            }),
                                            placeholder: (styles: any) => ({
                                              ...styles,
                                              fontSize: '14px',
                                              color: '#9B9B9B',
                                            }),
                                          }}
                                        />
                                      </Box>
                                      <FormErrorMessage>
                                        Recruiter is required.
                                      </FormErrorMessage>
                                    </FormControl>

                                    <FormControl
                                      isInvalid={Boolean(
                                        !!(errors as any)?.recruiters?.[index]
                                          ?.split_percentage &&
                                          (touched as any)?.recruiters?.[index]
                                            ?.split_percentage
                                      )}
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="19.1px"
                                        color="#2B2D42"
                                        fontWeight={700}
                                      >
                                        Split Percentage{' '}
                                        <Box as="span" color="#C53030">
                                          *
                                        </Box>
                                      </FormLabel>

                                      <Box
                                        border={
                                          !!(errors as any)?.recruiters?.[index]
                                            ?.split_percentage &&
                                          (touched as any)?.recruiters?.[index]
                                            ?.split_percentage &&
                                          '1px solid #E53E3E'
                                        }
                                        borderRadius={
                                          !!(errors as any)?.recruiters?.[index]
                                            ?.split_percentage &&
                                          (touched as any)?.recruiters?.[index]
                                            ?.split_percentage &&
                                          '4px'
                                        }
                                        boxShadow={
                                          !!(errors as any)?.recruiters?.[index]
                                            ?.split_percentage &&
                                          (touched as any)?.recruiters?.[index]
                                            ?.split_percentage &&
                                          '0 0 0 1px #E53E3E'
                                        }
                                      >
                                        <InputGroup>
                                          <InputLeftElement
                                            pointerEvents="none"
                                            zIndex={0}
                                            children={
                                              <Icon
                                                as={BsPercent}
                                                color="#718096"
                                              />
                                            }
                                            sx={{
                                              height: '38px',
                                            }}
                                          />
                                          <Field
                                            as={Input}
                                            id={split_name}
                                            name={split_name}
                                            placeholder="0"
                                            type="number"
                                            variant="outline"
                                            pl="35px"
                                            max={100}
                                            min={0}
                                            value={recruiter?.split_percentage}
                                            maxLength={3} // Limit the input to 3 digits
                                            onChange={(e: any) => {
                                              const value = e.target.value;
                                              // Regex to allow only numbers with a maximum of 3 digits
                                              if (/^\d{0,3}$/.test(value)) {
                                                handleChange(e);
                                                localStorage.setItem(
                                                  'jobFormData',
                                                  JSON.stringify({
                                                    ...jobFormData,
                                                    ...values,
                                                  })
                                                );
                                              }
                                            }}
                                            sx={{
                                              height: '38px',
                                              border: '1px solid #E7EDF4',
                                              p: '8px 12px',
                                              pl: '35px',
                                              fontSize: '14px',
                                            }}
                                          />
                                        </InputGroup>
                                      </Box>

                                      <FormErrorMessage>
                                        Split percentage is required.
                                      </FormErrorMessage>
                                    </FormControl>
                                  </Flex>

                                  <Box mb="24px" color="#2B2D42">
                                    <Checkbox
                                      colorScheme="purple"
                                      id={post_new_job_ads}
                                      name={post_new_job_ads}
                                      isChecked={recruiter.post_new_job_ads}
                                      onChange={(e: any) => {
                                        handleChange(e);
                                        localStorage.setItem(
                                          'jobFormData',
                                          JSON.stringify({
                                            ...jobFormData,
                                            ...values,
                                          })
                                        );
                                      }}
                                    >
                                      Allow recruiter to post new job (Enabling
                                      this will share the client with the
                                      recruiter)
                                    </Checkbox>
                                  </Box>

                                  <Box mb="24px">
                                    <FormLabel
                                      fontSize="14px"
                                      lineHeight="19.1px"
                                      color="#2B2D42"
                                      fontWeight={700}
                                    >
                                      Submittal Review
                                    </FormLabel>

                                    <RadioGroup
                                      value={selectedReview}
                                      onChange={handleReview}
                                    >
                                      <Stack spacing="10px">
                                        <Radio
                                          colorScheme="purple"
                                          value="no_submittal_review"
                                        >
                                          No Submittal Review
                                        </Radio>
                                        <Radio
                                          colorScheme="purple"
                                          value="review_send_as_client_owner"
                                        >
                                          Review Send as Client Owner
                                        </Radio>
                                        <Radio
                                          colorScheme="purple"
                                          value="review_send_as_client_recruiter"
                                        >
                                          Review Send as Client Recruiter
                                        </Radio>
                                      </Stack>
                                    </RadioGroup>
                                  </Box>

                                  <Box mb="24px">
                                    <FormLabel
                                      fontSize="14px"
                                      lineHeight="19.1px"
                                      color="#2B2D42"
                                      fontWeight={700}
                                    >
                                      Interview Settings
                                    </FormLabel>

                                    <RadioGroup
                                      value={selectedInterview}
                                      onChange={handleInterview}
                                    >
                                      <Stack spacing="10px">
                                        <Radio
                                          colorScheme="purple"
                                          value="interview_send_as_client_owner"
                                        >
                                          Send interview as client owner
                                        </Radio>
                                        <Radio
                                          colorScheme="purple"
                                          value="interview_send_as_recruiter"
                                        >
                                          Send interview as recruiter
                                        </Radio>
                                      </Stack>
                                    </RadioGroup>
                                  </Box>
                                </Box>

                                <Box sx={{ w: 'fit-content' }}>
                                  <Button
                                    leftIcon="plus"
                                    onClick={() =>
                                      push({
                                        name: '',
                                        split_percentage: null,
                                        post_new_job_ads: false,
                                        no_submittal_review: false,
                                        review_send_as_client_owner: false,
                                        review_send_as_client_recruiter: false,
                                        interview_send_as_client_owner: false,
                                        interview_send_as_recruiter: false,
                                      })
                                    }
                                  >
                                    Add Recruiter
                                  </Button>
                                </Box>
                              </Box>
                            );
                          }
                        )}

                      {values.recruiters?.length === 0 && (
                        <Box
                          sx={{
                            h: '50vh',
                          }}
                        >
                          <Button
                            sx={{ w: 'fit-content' }}
                            leftIcon="plus"
                            onClick={() =>
                              push({
                                name: '',
                                split_percentage: null,
                                post_new_job_ads: false,
                                no_submittal_review: false,
                                review_send_as_client_owner: false,
                                review_send_as_client_recruiter: false,
                                interview_send_as_client_owner: false,
                                interview_send_as_recruiter: false,
                              })
                            }
                          >
                            Add Recruiter
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  )}
                </FieldArray>
              </Box>
            </Stack>

            <ModalFooter
              position="sticky"
              bottom="0"
              background="#F9F9FB"
              p="8px 0"
            >
              <Flex
                gap="10px"
                justifyContent="space-between"
                sx={{ w: '100%' }}
              >
                {tabIndex.id > 0 && (
                  <Button
                    disabled={isOpenAlert}
                    sx={{
                      height: '40px',
                    }}
                    mr={3}
                    onClick={() => {
                      tabIndex.id > 0 && setTabIndex(tabList[tabIndex.id - 1]);
                      setJobFormData((prev: any) => ({
                        ...prev,
                        ...values,
                      }));
                    }}
                  >
                    Previous
                  </Button>
                )}

                <Flex gap="10px">
                  <ButtonChakra
                    onClick={() => {
                      onClose();
                      localStorage.setItem('jobFormData', JSON.stringify({}));
                    }}
                  >
                    Cancel
                  </ButtonChakra>

                  {!isEditModal && (
                    <Button
                      disabled={isOpenAlert || loading}
                      loading={loading}
                      mr={3}
                      onClick={() => {
                        setIsDraft(true);
                        handleSubmit();
                        !isValid && onOpen();
                      }}
                    >
                      Save Draft
                    </Button>
                  )}

                  {((String(jobFormData.job_type_id) === '1' &&
                    tabIndex.id < 4) ||
                    (String(jobFormData.job_type_id) === '2' &&
                      tabIndex.id < 2)) && (
                    <Button
                      variant="solid"
                      disabled={isOpenAlert}
                      onClick={(e: any) => {
                        setIsDraft(false);
                        handleSubmit(e);
                        console.log("initialValues-values",values);
                      }}
                    >
                      Next
                    </Button>
                  )}
                </Flex>
              </Flex>
            </ModalFooter>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default RecruitingTeam;
