import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import ClientsContactsOverview from './Overview';
import { getContact } from 'store/contact.slice';
import ClientsJobs from '../Tabs/Jobs';
import ClientsActivityLog from '../Tabs/ActivityLog';
import { useLocation } from 'react-router';
import { LeadsEmails } from 'modules/Leads/TabList/TabPanel/Emails';
import { listLeads, useGetEmailsMutation } from 'store/leads.slice';

const ClientsContactsTabs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const currentTab = params.clientSubSubTab || params.clientsSubTab;
  const { contactList, contactData } = useSelector(
    (state: any) => state.contacts
  );

  const tabIndex: any = {
    overview: 0,
    jobs: 1,
    'activity-log': 2,
    emails: 3,
  };

  const clientID = params.clientsTabId || params.clientsId;

  useEffect(() => {
    if (clientID) {
      const getContactData = contactList.filter(
        (client: any) => client.id === Number(clientID)
      )[0];

      dispatch(getContact({ contactData: getContactData }));
    }
  }, [contactList, clientID, dispatch]);

  const handleRoute = (tab: number) => {
    const route = Object.keys(tabIndex)[tab];
    const path = params.clientSubSubTab
      ? `${params.clientsTab}/${params.clientsId}/${params.clientsSubTab}/${params.clientsTabId}`
      : `${params.clientsTab}/${params.clientsId}`;
    navigate(`${path}/${route}`);
  };

  const [reqGetEmails] = useGetEmailsMutation();

  useEffect(() => {
    if (currentTab === 'emails') {
      reqGetEmails({ id: contactData?.id });
    } else {
      dispatch(listLeads({ emails: [] }));
    }
  }, [contactData, currentTab]);

  return (
    <Tabs
      colorScheme="purple"
      index={tabIndex[currentTab]}
      onChange={handleRoute}
    >
      <TabList fontSize="md" fontWeight="bold">
        <Tab _focus={{ boxShadow: 'none !important' }}>Overview</Tab>
        <Tab _focus={{ boxShadow: 'none !important' }}>Jobs</Tab>
        <Tab _focus={{ boxShadow: 'none !important' }}>Activity Logs</Tab>
        <Tab _focus={{ boxShadow: 'none !important' }}>Emails</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <ClientsContactsOverview />
        </TabPanel>
        <TabPanel>
          <ClientsJobs />
        </TabPanel>
        <TabPanel>
          <ClientsActivityLog />
        </TabPanel>
        <TabPanel>
          <LeadsEmails />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ClientsContactsTabs;
