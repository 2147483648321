import Table from 'modules/Leads/components/table/UnclaimedTable';
import { UNCLAIMED } from 'modules/Leads/constants/filter-constants';
import {
  useAddLeadMutation,
  useFetchLeadsQuery,
  useSendForApprovalMutation,
  useSetBulkLeadsInactiveMutation,
  useSetCancelApprovalMutation,
  useSetLeadActiveMutation,
  useSetLeadInactiveMutation,
  useSetLeadToClientMutation,
} from 'modules/Leads/service/leadsApi';
import { DateSort, LeadDetailDrawerContextType } from 'modules/Leads/types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import { resetFilter } from 'store/leads.slice';
import { swalContent } from 'types';
import { AtsConfirm, ConfirmWithReason } from 'utils/swal';

let content: swalContent = {
  title: 'Are you sure?',
  text: 'You are about to mark this lead as inactive, continue?',
  buttons: ['Cancel', true],
  icon: 'warning',
};
export default function UnclaimedLeads() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, query } = useSelector(
    (state: {
      leads: { status: number[]; dateFilter: string; query: string };
    }) => state.leads
  );
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(50);
  const [sortOrder, setSortOrder] = useState<DateSort>('DESC');
  const [leadDetailRowId, setLeadDetailRowId] = useState<number | null>(null);

  const { data, isFetching } = useFetchLeadsQuery({
    unclaimed: true,
    is_prospect: 0,
    is_repeat_prospect: null,
    types: [] as number[],
    hot: false,
    qualified: false,
    status,
    query,
    date_filter: null,
    page,
    take,
    orderBy: {
      column_name: 'created_at',
      sort_order: sortOrder,
    },
  });
  const [reqAddLead] = useAddLeadMutation();
  const [reqSendForApproval] = useSendForApprovalMutation();
  const [reqSetBulkLeadsInActive] = useSetBulkLeadsInactiveMutation();
  const [reqSetCancelApproval] = useSetCancelApprovalMutation();
  const [reqSetLeadActive] = useSetLeadActiveMutation();
  const [reqSetLeadInActive] = useSetLeadInactiveMutation();
  const [reqSetLeadToClient] = useSetLeadToClientMutation();

  function handlePageChange(page: number) {
    setPage(page);
  }
  function handleEntriesChange(take: number) {
    setTake(take);
  }
  function handleDateSort() {
    setSortOrder((state) => (state === 'DESC' ? 'ASC' : 'DESC'));
  }
  async function handleNextDrawerPagination() {
    //  const res= await fetchLeads()
    if (take === leadDetailRowId + 1 && data.count > take) {
      //reached end of current table pagination data
    } else {
      const nextLeadDetail = data.data[leadDetailRowId + 1];
      setLeadDetailRowId((state) => state + 1);
      navigate(`/leads/unclaimed-leads/${nextLeadDetail.id}/details`);
    }
  }
  function handlePrevDrawerPagination() {
    if (leadDetailRowId >= 0) {
      const prevLeadDetail = data.data[leadDetailRowId - 1];
      setLeadDetailRowId((state) => state - 1);
      navigate(`/leads/unclaimed-leads/${prevLeadDetail.id}/details`);
    }
  }

  async function onAddLead(formField: any) {
    try {
      const payload = await reqAddLead(formField).unwrap();
      return payload;
    } catch (err: any) {
      return err.data;
    }
  }
  async function onBulkInActive(idList: number[]) {
    const submittedReason = await ConfirmWithReason(content);
    if (submittedReason) {
      await reqSetBulkLeadsInActive({
        data: { idList, inactive_reason: submittedReason },
      });
    }
  }
  async function onCancelApproval(lead: any) {
    content.text = 'You are about to cancel this approval, continue?';
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqSetCancelApproval({ id: lead.id });
    }
  }
  async function onChangeToActiveStatus(lead: any) {
    content.text = 'You are about to mark this lead as inactive, continue?';
    const confirm = await AtsConfirm(content);

    if (confirm) {
      await reqSetLeadActive({ id: lead.id });
    }
  }
  async function onChangeToInActiveStatus(lead: any) {
    const submittedReason = await ConfirmWithReason(content);

    if (submittedReason) {
      await reqSetLeadInActive({
        id: lead.id,
        inactive_reason: submittedReason,
      });
    }
  }
  async function onConvertToClient(formField: any, lead: any) {
    try {
      const payload = await reqSetLeadToClient({
        data: formField,
        id: lead.id,
      }).unwrap();
      return payload;
    } catch (err: any) {
      return err.data;
    }
  }
  async function onSendApproval(formField: any, lead: any) {
    try {
      const payload = await reqSendForApproval({
        data: formField,
        id: lead.id,
      }).unwrap();
      return payload;
    } catch (err: any) {
      return err.data;
    }
  }

  useEffect(() => {
    const status = UNCLAIMED[0].items
      .filter((stat) => stat.status)
      .map((item) => item.id);
    dispatch(resetFilter({ status }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table
        onDateSort={handleDateSort}
        onSubmitEmail={() => {}}
        onAddLead={onAddLead}
        onBulkInActive={onBulkInActive}
        onCancelApproval={onCancelApproval}
        onChangeToActiveStatus={onChangeToActiveStatus}
        onChangeToInActiveStatus={onChangeToInActiveStatus}
        onConvertToClient={onConvertToClient}
        onSendApproval={onSendApproval}
        onOpenDetailDrawer={(index: number) => setLeadDetailRowId(index)}
        rows={data?.data ?? []}
        isLoading={isFetching}
        pagination={{
          onEntryChange: handleEntriesChange,
          onPageChange: handlePageChange,
          currentCount: data?.data?.length,
          currentPage: page,
          targetCount: take,
          totalEntries: data?.count,
          totalPages: data?.lastPage,
        }}
      />

      {/* Render drawer */}
      <Outlet
        context={
          {
            rowId: leadDetailRowId + 1,
            totalLeads: data?.count,
            handlePrevData: handlePrevDrawerPagination,
            handleNextData: handleNextDrawerPagination,
            onClose: () => navigate('/leads/unclaimed-leads'),
          } satisfies LeadDetailDrawerContextType
        }
      />
    </>
  );
}
