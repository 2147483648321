// Chakra UI components
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  TabIndicator,
  Tabs,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

// External libraries
import { number } from 'yup';

// Custom components
import LoadingEdit from './Layout/LoadingEdit';

// Services
import { useJobModalServices } from './useModalServices';

// React hooks
import { useEffect,useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { JobsTabPanel } from './Layout/TabPanel';
import { JobsTabList } from './Layout/TabList';

interface JobsModalCreateProps {
  isOpen: any;
  onClose: any;
  modalTitle: string;
  duplicate?: boolean;
  isDrawer?: boolean;
  companyDef?: any;
  contactDef?: any;
  searchableLeads?: any;
  searchableClient?: any;
  contactOptionValue?: any;
  jobId?: number;
}

const JobsModal = ({
  isOpen,
  onClose,
  duplicate = false,
  isDrawer,
  companyDef = [],
  contactDef = [],
  searchableLeads = null,
  searchableClient = null,
  contactOptionValue = [],
  jobId = null,
}: JobsModalCreateProps) => {
  const {
    handleClose,
    currentName,
    geo_location,
    setItemLocation,
    modalHeader,
    isOpenAlert,
    jobStatus,
    errMsg,
    errorList,
    onCloseAlert,
    tabIndex,
    tabList,
    isLoading,
    setJobStatus,
    hasJobPostType,
    initialClientValues,
    setJobFormData,
    isDraft,
    jobFormData,
    aMOption,
    jobData,
    auth,
    onSubmit,
    initialValues,
    changeToDefault,
    setTabIndex,
    setHasJobPostType,
    defaultPitch,
    isEditModal,
    CompanyOption,
    CompanyChange,
    contactOption,
    locationOption,
    ccOption,
    onCcInputChange,
    bccOption,
    onBccInputchange,
    setIsDraft,
    initialJobDetailsValues,
    initialRecruitersValues,
    jobPairTemplate,
    jobPairing,
    onOpen,
    recOptions,
    setProtocol,
    protocol,
    handleProtocol,
    validationSchema,
    placeholderOptions,
    placeHolderClick,
    placholders,
    initialJobAdsValues,
    geoLocationChange,
    countryOption,
    itemLocation,
    setErrorList,
    setName,
    isLoadingCompany,
    isLoadingGeo,
    setSelectedCompanyName,
    jobSpecsOption,
    handleJobSpec,
    setAmOption,
    setContactOption,
    setContact,
    contactDefSet,
    companyDefSet,
    setNewClientContactOptions,
    setSelectedJobSpec,
    setInitialClientValue,
    geyDefValues
  } = useJobModalServices({
    jobId,
    duplicate,
    isOpen,
    companyDef,
    contactOptionValue,
    onClose,
    isDrawer,
    contactDef,
  });
  
  const { clientData } = useSelector((state: any) => state.clients);
  const { leadDetails } = useSelector((state: any) => state.leads);
  const params = useParams();
 

  useEffect(() => {
    const clientsTabId = params.clientsTabId || null;
    const leadsId = params.leadsId || null;
    let amOptions_ :any = [];
    let industry:any = {};
    let contact:any = {};
    if(clientData)
    {
      clientData?.manager?.map((item:any) => {
        amOptions_.push({
          label: [item?.user?.first_name, item?.user?.last_name].join(' '),
          value: item?.user?.id,
        });
      });
      setAmOption(amOptions_);

      
      setSelectedJobSpec({
        label: clientData?.specs?.specification,
        value: clientData?.specs?.id,
      });

      industry = {
        label: clientData?.specs?.specification,
        value: clientData?.specs?.id,
      };
    }
    

    if(clientsTabId !== null)
    {
      let options: any = [];

      clientData?.leads?.map((item: any) => {
        if(clientsTabId !== null && item.id == clientsTabId){
          options.push({
            label: [item.first_name, item.last_name].join(' '),
            value: item.id
          });
          contact =  {
              label: [item.first_name, item.last_name].join(' '),
              value: item.id
          }
        }
        if(clientsTabId == null)
        {
          options.push({
            label: [item.first_name, item.last_name].join(' '),
            value: item.id,
          });
        }
      });
      if(options && options.length >= 1)
      {
        setContactOption(options);
        console.log("options", options);
      }
    }

    if(leadsId !== null)
    {
      let options: any = [];

      if(leadDetails?.lead)
      {
        options.push({
          label: [leadDetails?.lead?.first_name, leadDetails?.lead?.last_name].join(' '),
          value: leadDetails?.lead?.id,
        });

        contactDefSet({
          label: [leadDetails?.lead?.first_name, leadDetails?.lead?.last_name].join(' '),
          value: leadDetails?.lead?.id,
        });

        companyDefSet({
          label: leadDetails?.client?.name,
          value: leadDetails?.client?.id,
        });

        setContactOption(options);
        contact = {
          label: [leadDetails?.lead?.first_name, leadDetails?.lead?.last_name].join(' '),
          value: leadDetails?.lead?.id,
        };
        setNewClientContactOptions(leadDetails?.client?.id, [leadDetails?.client], leadDetails?.lead?.id);
      }

    }

    setInitialClientValue({...geyDefValues(), industry, contact});

  }, [clientData]);
  

  const geoFormat = (item: any) => {
    let format: any = '';
    try {
      const cityB = currentName == 'city' ? { fontWeight: 'bold' } : {};
      const postB = currentName == 'postal_code' ? { fontWeight: 'bold' } : {};
      format = (
        <Flex gap="2px">
          <Text style={postB}>{item?.postal_code}</Text>
          <Text style={cityB}>{item?.city}</Text>
          <Text>{item?.state}</Text>
          <Text>{item?.country}</Text>
        </Flex>
      );
    } catch (e) {
      format = 'Error formatting';
    }
    return format;
  };

  const setGeoItem = () => {
    if (geo_location.length > 0) {
      let optios: any = [];
      geo_location.map((item: any, key: number) => {
        const value = currentName == 'city' ? item.city : item.postal_code;
        optios.push({
          city: item.city,
          country: item.country,
          postal_code: item.postal_code,
          country_code: item.country_code,
          state: item.state,
          value: value,
          label: geoFormat(item),
          id: number,
        });
      });
      setItemLocation(optios);
    } else {
      setItemLocation([]);
    }
  };

  useEffect(() => {
    setGeoItem();
  }, [geo_location, currentName]);

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={handleClose}
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxW={{
          '1280px': '1200px', // 1280 x 832
          '1366px': '1200px', // 1366 x 768
          '1400px': '1300px', // 1400 x 900
          '1512px': '1400px', // 1512 x 982
          '1728px': '1500px', // 1728 x 1117
          '1920px': '1500px', // 1920 x 1280
        }}
        w={{
          '1280px': '1200px', // 1280 x 832
          '1366px': '1200px', // 1366 x 768
          '1400px': '1300px', // 1400 x 900
          '1512px': '1400px', // 1512 x 982
          '1728px': '1500px', // 1728 x 1117
          '1920px': '1500px', // 1920 x 1280
        }}
        maxH={{
          '1280px': '550px', // 1280 x 832
          '1366px': '550px', // 1366 x 768
          '1400px': '700px', // 1400 x 900
          '1512px': '700px', // 1512 x 982
          '1728px': '800px', // 1728 x 1117
          '1920px': '800px', // 1920 x 1280
        }}
        h={{
          '1280px': '550px', // 1280 x 832
          '1366px': '550px', // 1366 x 768
          '1400px': '700px', // 1400 x 900
          '1512px': '700px', // 1512 x 982
          '1728px': '800px', // 1728 x 1117
          '1920px': '800px', // 1920 x 1280
        }}
        fontFamily="NunitoSans Regular"
      >
        <ModalHeader
          sx={{
            background: '#F9FAFB',
            borderRadius: '4px 4px 0',
            p: '16px',
          }}
        >
          <Text
            sx={{
              py: '5px',
              fontSize: '18px',
              lineHeight: '18px',
              fontWeight: 700,
              color: '#2B2D42',
              textAlign: 'left',
            }}
          >
            {modalHeader}
          </Text>
        </ModalHeader>

        {isOpenAlert && jobStatus !== 5 && (
          <Box>
            <Alert
              status="error"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Flex gap="20px" position="relative" left="20px">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>
                    {errMsg || (
                      <>
                        There were some problems in you input. Change a few
                        things up and try again.
                        <UnorderedList
                          mt="10px"
                          fontSize="13px"
                          lineHeight="1.5"
                          color="var(--chakra-colors-red-500)"
                        >
                          {Object.values(errorList).map((key: any) => (
                            <ListItem key={key}>{key}</ListItem>
                          ))}
                        </UnorderedList>
                      </>
                    )}
                  </AlertDescription>
                </Box>
              </Flex>

              <CloseButton
                position="absolute"
                right="20px"
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}

        <ModalBody
          sx={{
            bgColor: '#F9FAFB',
            borderRadius: '0 0 4px 4px',
            p: 0,
            h: '100%',
          }}
        >
          <Box position="relative" h="inherit">
            <Tabs colorScheme="purple" index={tabIndex.id} h="inherit">
              <Stack gap={2} background="#F9F9FB" h="inherit">
                <JobsTabList
                  tabList={tabList}
                  isOpen={isOpen}
                  tabIndex={tabIndex}
                />
                <TabIndicator color="#6930CA" />
                <Box
                  width="100%"
                  h="inherit"
                  sx={{
                    p: '0 16px',
                  }}
                >
                  {isLoading ? (
                    <LoadingEdit />
                  ) : (
                    <JobsTabPanel
                      setJobStatus={setJobStatus}
                      hasJobPostType={hasJobPostType}
                      initialClientValues={initialClientValues}
                      setJobFormData={setJobFormData}
                      isDraft={isDraft}
                      jobFormData={jobFormData}
                      aMOption={aMOption}
                      jobData={jobData}
                      auth={auth}
                      onSubmit={onSubmit}
                      initialValues={initialValues}
                      changeToDefault={changeToDefault}
                      setTabIndex={setTabIndex}
                      setHasJobPostType={setHasJobPostType}
                      defaultPitch={defaultPitch}
                      isEditModal={isEditModal}
                      CompanyOption={CompanyOption}
                      CompanyChange={CompanyChange}
                      contactOption={contactOption}
                      locationOption={locationOption}
                      ccOption={ccOption}
                      onCcInputChange={onCcInputChange}
                      bccOption={bccOption}
                      onBccInputchange={onBccInputchange}
                      setIsDraft={setIsDraft}
                      initialJobDetailsValues={initialJobDetailsValues}
                      initialRecruitersValues={initialRecruitersValues}
                      jobPairTemplate={jobPairTemplate}
                      jobPairing={jobPairing}
                      onOpen={onOpen}
                      recOptions={recOptions}
                      setProtocol={setProtocol}
                      protocol={protocol}
                      handleProtocol={handleProtocol}
                      validationSchema={validationSchema}
                      placeholderOptions={placeholderOptions}
                      placeHolderClick={placeHolderClick}
                      placholders={placholders}
                      initialJobAdsValues={initialJobAdsValues}
                      geoLocationChange={geoLocationChange}
                      countryOption={countryOption}
                      itemLocation={itemLocation}
                      setErrorList={setErrorList}
                      setName={setName}
                      isLoadingCompany={isLoadingCompany}
                      isLoadingGeo={isLoadingGeo}
                      tabList={tabList}
                      tabIndex={tabIndex}
                      searchableLeads={searchableLeads}
                      searchableClient={searchableClient}
                      isOpenAlert={isOpenAlert}
                      isLoading={isLoading}
                      modalHeader={modalHeader}
                      jobId={jobId}
                      jobStatus={jobStatus}
                      onClose={onClose}
                      setSelectedCompanyName={setSelectedCompanyName}
                      jobSpecsOption={jobSpecsOption}
                      handleJobSpec={handleJobSpec}
                    />
                  )}
                </Box>
              </Stack>
            </Tabs>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default JobsModal;
