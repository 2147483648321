// Import statements
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { CAREER_PAGE_URL } from 'constants/values';
import { useEffect, useState } from 'react';
import replacePlaceholders from 'utils/textPlaceholders';

// Interface definition for props
interface useJobAdsServicesProps {
  setJobStatus: any;
  jobId: any;
  jobFormData: any;
  jobData: any;
  jobPairTemplate: any;
  jobPairing: any;
  setJobFormData: any;
  isDraft: any;
  initialValues: any;
  tabList: any;
  tabIndex: any;
  setTabIndex: any;
  onSubmit: any;
}

// Main function definition
export const useJobAdsServices = ({
  setJobStatus,
  jobId,
  jobFormData,
  jobData,
  jobPairTemplate,
  jobPairing,
  setJobFormData,
  isDraft,
  initialValues,
  tabList,
  tabIndex,
  setTabIndex,
  onSubmit,
}: useJobAdsServicesProps) => {
  // Access constants from Redux store
  const { work_types, countries } = useSelector(
    (state: any) => state.constants
  );

  const { placehoderPair, templateRawList } = useSelector(
    (state: any) => state.template
  );

  const [previewOpen, setPreviewOpen] = useState(false);

  const [placehoder, setPlaceHoders] = useState(placehoderPair);

  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);

  // Define Yup validation schema for job ads
  const validationJobAdsSchema = Yup.lazy((values) => {
    const currentJobStatus = values.job_status_id;
    setJobStatus(currentJobStatus);

    // If job status is 5, validation is not required
    if (currentJobStatus === 5) return Yup.mixed().notRequired();

    // Define validation schema
    return Yup.object().shape({
      job_ads: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required('Posting Title is required'),
          state: Yup.string().required('State is required').nullable(),
          city: Yup.string().required('City is required').nullable(),
          zip_code: Yup.string().required('Zip Code is required').nullable(),
          country: Yup.object().required('Country is required').nullable(),
          description: Yup.string().required('Description is required.'),
          career_page_publish: Yup.boolean(),
          show_profile: Yup.boolean(),
          free_job_board_publish: Yup.boolean(),
          paid_job_board_publish: Yup.boolean(),
          active: Yup.number(),
          worktype_id: Yup.number().required('Work Type is required'),
          id: Yup.number().nullable(),
        })
      ),
    });
  });

  const placholders = (text: string) => {
    return replacePlaceholders(text, placehoder);
  };

  // Function to handle job ads submission
  const jobAdsSubmit = (data: any, { setSubmitting, validateForm }: any) => {
    let url = `${CAREER_PAGE_URL}/job-details`;
    let job_name_url = '';
    let job_posting_title = '';

    // If jobId or jobFormData exists, modify the URL
    if (jobId !== null || jobFormData?.job_id !== null) {
      const ads = jobData?.jobAds?.[0];
      if (ads) {
        url = `${url}/${ads?.id}`;
      }
    }

    // Create job name URL and job posting title
    job_posting_title = data.job_ads[0].title;
    job_name_url = `<a href="${url}" style="color: blue; text-decoration: underline" target="_blank">${data.job_ads[0].title}</a>`;

    // Generate job URL and prepare the pairing template
    const job_url = `<a href="${url}" style="color: blue; text-decoration: underline" target="_blank">${url}</a>`;
    const pairTemplate = {
      ...jobPairTemplate,
      job_url: job_url,
      job_posting_title: job_posting_title,
      job_name_url: job_name_url,
    };

    // Perform job pairing
    jobPairing(pairTemplate);

    // Update job form data if not a draft
    if (isDraft === false) {
      setJobFormData((prev: any) => ({
        ...prev,
        ...data,
      }));
    }

    // End submitting and validate the form
    setSubmitting(false);
    validateForm().then((errors: any) => {
      if (Object.keys(errors).length === 0) {
        // Submit or move to the next tab if there are no errors
        if (isDraft === true) {
          onSubmit({
            ...initialValues,
            ...jobFormData,
            ...data,
            job_status_id: 5,
          });
        } else if (tabList?.length !== tabIndex.id + 1) {
          setTabIndex(tabList[tabIndex.id + 1]);
        }
      }
    });
  };

  // Return the necessary functions and values
  return {
    validationJobAdsSchema,
    jobAdsSubmit,
    work_types,
    countries,
    previewOpen,
    setPreviewOpen,
    placholders,
  };
};
