import { Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router';
import LeadSideBar from './Sidebar';

export default function Layout() {
  return (
    <Flex
      gap="8px"
      sx={{
        mt: '4px',
        p: '8px',
        fontFamily: 'NunitoSans Regular',
        overflow: 'hidden',
        height: '90vh',
      }}
    >
      <LeadSideBar />

      <Stack
        spacing={0}
        sx={{
          height: '100%',
          w: 'calc(100% - 320px)',
          bgColor: '#fff',
          borderRadius: '10px',
          p: '16px',
        }}
      >
        <Outlet />
      </Stack>
    </Flex>
  );
}
