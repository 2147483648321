import Table from 'modules/Leads/components/table/NewProspectsTable';
import { PROSPECTS } from 'modules/Leads/constants/filter-constants';
import { useFetchLeadsQuery } from 'modules/Leads/service/leadsApi';
import { DateSort, LeadDetailDrawerContextType } from 'modules/Leads/types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import { resetFilter } from 'store/leads.slice';

export default function NewProspect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, query, dateFilter } = useSelector(
    (state: {
      leads: { status: number[]; dateFilter: string; query: string };
    }) => state.leads
  );
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(50);
  const [sortOrder, setSortOrder] = useState<DateSort>('DESC');
  const [leadDetailRowId, setLeadDetailRowId] = useState<number | null>(null);

  const { data, isFetching } = useFetchLeadsQuery({
    unclaimed: false,
    is_prospect: 1,
    is_repeat_prospect: 0,
    types: [] as number[],
    hot: false,
    qualified: false,
    status,
    query,
    date_filter: dateFilter,
    page,
    take,
    orderBy: {
      column_name: 'created_at',
      sort_order: sortOrder,
    },
  });

  function handlePageChange(page: number) {
    setPage(page);
  }
  function handleEntriesChange(take: number) {
    setTake(take);
  }

  function handleDateSort() {
    setSortOrder((state) => (state === 'DESC' ? 'ASC' : 'DESC'));
  }
  async function handleNextDrawerPagination() {
    //  const res= await fetchLeads()
    if (take === leadDetailRowId + 1 && data.count > take) {
      //reached end of current table pagination data
    } else {
      const nextLeadDetail = data.data[leadDetailRowId + 1];
      setLeadDetailRowId((state) => state + 1);
      navigate(`/leads/my-leads/${nextLeadDetail.id}/details`);
    }
  }
  function handlePrevDrawerPagination() {
    if (leadDetailRowId >= 0) {
      const prevLeadDetail = data.data[leadDetailRowId - 1];
      setLeadDetailRowId((state) => state - 1);
      navigate(`/leads/my-leads/${prevLeadDetail.id}/details`);
    }
  }

  useEffect(() => {
    const status = PROSPECTS[0].items
      .filter((stat) => stat.status)
      .map((item) => item.id);
    dispatch(resetFilter({ status }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table
        onDateSort={handleDateSort}
        onSubmitEmail={() => {}}
        rows={data?.data ?? []}
        isLoading={isFetching}
        pagination={{
          onEntryChange: handleEntriesChange,
          onPageChange: handlePageChange,
          currentCount: data?.data?.length,
          currentPage: page,
          targetCount: take,
          totalEntries: data?.count,
          totalPages: data?.lastPage,
        }}
      />

      {/* Render drawer */}
      <Outlet
        context={
          {
            rowId: leadDetailRowId + 1,
            totalLeads: data?.count,
            handlePrevData: handlePrevDrawerPagination,
            handleNextData: handleNextDrawerPagination,
            onClose: () => navigate('/leads/new-prospects'),
          } satisfies LeadDetailDrawerContextType
        }
      />
    </>
  );
}
