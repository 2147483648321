export function replaceWithPlaceholders(
  text: string,
  placeholders: Record<string, any>
) {
  const reversedObj: Record<string, string> = {};

  // Reverse the placeholders object to map values to keys
  for (const [key, value] of Object.entries(placeholders)) {
    if (value) {
      reversedObj[value.toString()] = `{{${key}}}`; // Ensure the value is treated as a string
    }
  }

  try {
    // Regex to detect `data-color` and `mark` elements with data attributes
    const markRegex = /<mark\b[^>]*data-color="([^"]*)"[^>]*>(.*?)<\/mark>/g;

    // Process each match of the `mark` tags with `data-color` attributes
    text = text.replace(markRegex, (match, colorValue, innerText) => {
      // Decompose `colorValue` into placeholder, length, and uuid
      const [placeholder, innerTextLength, uuid] = colorValue.split('.');

      // Check if we have a matching placeholder for this uuid in reversedObj
      const expectedPlaceholder = `{{${placeholder}}}`;
      if (
        reversedObj[`${placeholder}.${innerTextLength}.${uuid}`] ===
        expectedPlaceholder
      ) {
        return expectedPlaceholder; // Replace with the placeholder
      }
      return match; // Leave it unchanged if no match
    });

    // Create a regex to detect and replace `data-color` attribute values in other tags
    const dataColorRegex = /data-color="([^"]*)"/g;

    // Replace the `data-color` values if they match any in the reversedObj
    text = text.replace(dataColorRegex, (match, colorValue) => {
      if (reversedObj[colorValue]) {
        return match.replace(colorValue, reversedObj[colorValue]);
      }
      return match;
    });

    // Replace remaining standalone values in the text with placeholders
    for (const placeholder in reversedObj) {
      const regex = new RegExp(placeholder, 'g');
      text = text.replace(regex, reversedObj[placeholder]);
    }

    return text;
  } catch (e) {
    return text;
  }
}

export function replacePlaceholders(text: any, placeholders: any) {
  try {
    for (const placeholder in placeholders) {
      const regex = new RegExp(`{{${placeholder}}}`, 'g');
      text = text.replace(regex, placeholders[placeholder]);
    }
    return text;
  } catch (e) {
    return text;
  }
}

export function replacePlaceholdersHighlight(
  text: string,
  placeholders: Record<string, string>
) {
  try {
    // Replace placeholders with <mark> tags
    for (const [key, value] of Object.entries(placeholders)) {
      const regex = new RegExp(`{{${key}}}`, 'g');
      const keyVal = `{{${key}}}`;

      text = text.replace(
        regex,
        value === '&nbsp;'
          ? ''
          : `<mark data-color="${key}" style="background-color: #fbe7ff !important; color: inherit">${keyVal}</mark>`
      );
    }
    return text;
  } catch (e) {
    console.error('Error replacing placeholders:', e);
    return text;
  }
}

export const highlightPlaceholder = (input: string): string => {
  // Regular expression to find <mark> tags with data-color attribute
  const regex = /<mark\s+data-color="([^"]+)"[^>]*>(.*?)<\/mark>/g;

  // Map to store marks by UUID
  const markGroups: Record<
    string,
    { dataColor: string; innerContent: string; length: number }
  > = {};

  // Flag to track if any mismatched <mark> tags are removed
  let mismatchFound = false;

  // First pass to group marks by UUID
  input?.replace(regex, (match, dataColor, innerContent) => {
    // Extract the placeholder, length, and UUID from data-color
    const [placeholder, lengthUUID] = dataColor.split('.');
    const [placeholderLength, uuid] = lengthUUID.split('+');

    // Extract the plain text content from innerContent (ignore HTML tags)
    const textContent = innerContent.replace(/<[^>]*>/g, '').trim();
    const innerTextLength = textContent.length;

    // Group by UUID and accumulate content and length
    if (uuid) {
      if (!markGroups[uuid]) {
        markGroups[uuid] = {
          dataColor,
          innerContent,
          length: innerTextLength,
        };
      } else {
        markGroups[uuid].innerContent += innerContent;
        markGroups[uuid].length += innerTextLength;
      }
    }

    return ''; // No need to return anything in replace function for now
  });

  // Second pass to rebuild or remove marks based on length validation
  let result = input.replace(regex, (match, dataColor, innerContent) => {
    // Extract the placeholder, length, and UUID from data-color
    const [placeholder, lengthUUID] = dataColor.split('.');
    const [placeholderLength, uuid] = lengthUUID.split('+');

    // Convert placeholderLength to integer for comparison
    const expectedLength = parseInt(placeholderLength, 10);

    // Find the group with the same UUID
    const group = markGroups[uuid];

    // If the lengths match, return the original marks; otherwise, remove them
    if (group && group.length === expectedLength) {
      return match; // Keep the original mark
    } else {
      // If the length does not match, remove the mark and set mismatch flag
      mismatchFound = true;
      return ''; // Replace the mark with an empty string
    }
  });

  // Conditionally remove <ol type="1"> and <p><br class="ProseMirror-trailingBreak"></p> tags if any mismatch was found
  if (mismatchFound) {
    result = result.replace(/<ol\s+type="1">.*?<\/ol>|<p><\/p>/g, '');
  }

  return result;
};
