import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import AtsSelect from 'components/app/AtsSelect';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useFetchJobSpecificationsQuery } from 'services/constants/constantsApi';
import { InitialValueType } from '../type';

export default function ClientDetails() {
  const formikContext = useFormikContext<InitialValueType>();
  const { data: jobSpecificationList, isSuccess } =
    useFetchJobSpecificationsQuery({});
  const [jobSpecificationValue, setJobSpecificationValue] = useState({
    label: '',
    value: '',
  });
  const jobSpecificationOptions =
    jobSpecificationList?.map((job: any) => ({
      label: job.specification,
      value: job.id,
    })) ?? [];

  useEffect(() => {
    if (isSuccess) {
      const defaultJobSpecification = jobSpecificationOptions.find(
        (job: any) => job.id === formikContext.values.specification_id
      );
      setJobSpecificationValue(defaultJobSpecification);
    }
  }, [isSuccess]);
  return (
    <>
      <Flex gap="32px" mb="34px">
        <Field name="client_name">
          {({
            form: { errors, touched, values },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.client_name && touched.client_name)}
              isRequired
            >
              <FormLabel>Client Name </FormLabel>
              <Field
                as={Input}
                id="client_name"
                name="client_name"
                type="text"
                placeholder="Client Name"
                variant="outline"
                value={values.client_name}
              />
              <FormErrorMessage>{String(errors.client_name)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="website">
          {({
            form: { errors, touched, values },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.website && touched.website)}
              isRequired
            >
              <FormLabel>Website </FormLabel>
              <Field
                as={Input}
                id="website"
                name="website"
                type="text"
                placeholder="Website"
                variant="outline"
                value={values.website}
              />
              <FormErrorMessage>{String(errors.website)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Flex>
      <Flex gap="32px" mb="34px">
        <Field name="specification_id">
          {({
            form: { errors, touched, setFieldValue },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(
                !!errors.specification_id && touched.specification_id
              )}
              isRequired
            >
              <FormLabel>Industry </FormLabel>

              <Field
                name="specification_id"
                options={jobSpecificationOptions}
                component={AtsSelect}
                placeholder="Select"
                value={jobSpecificationValue}
                onChange={(e: any) => {
                  setFieldValue('specification_id', String(e.value));
                  setJobSpecificationValue(e);
                }}
              />

              <FormErrorMessage>
                {String(errors.specification_id)}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Flex>
    </>
  );
}
